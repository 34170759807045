// SampleTasks.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

const SampleTasks = ({ tasks, handleTaskClick }) => {
    return (
        <Box sx={{
            display: 'flex',
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            flexWrap: 'nowrap',  // Prevent wrapping of items
            overflowX: 'scroll',
            padding: 2,
            paddingTop: 1,
            zIndex: -100,
            '&::-webkit-scrollbar': {
                display: 'none', // hiding scrollbar for WebKit
            },
            scrollbarWidth: 'none', // hiding scrollbar for Firefox
            '-ms-overflow-style': 'none', // hiding scrollbar for IE and Edge
        }}>
            {tasks.map((task, index) => (
                <Paper sx={{ p: 2, textAlign: 'center', borderRadius: 2, marginRight: 2 }}>
                    <Typography
                    variant="outlined"
                    sx={{ 
                        whiteSpace: 'nowrap',  // Keep the text on a single line
                        overflow: 'hidden',  // Hide overflow
                        textOverflow: 'ellipsis',  // Add ellipsis for overflow text
                        maxWidth: 240, // Optional: you might want to limit max width,
                        minWidth: 120 // Optional: minimum width for smaller entries
                    }}
                    key={index}
                    onClick={() => handleTaskClick(task)}
                >
                    {task}
                </Typography>
                </Paper>
            ))}
        </Box>
    );
};

export default SampleTasks;
