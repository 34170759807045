import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
  TextField,
  Alert,
  Snackbar,
  CircularProgress,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { supabase } from "../supabaseClient";
import axios from "axios";
import { API_URL } from "../globals";
import { useNavigate } from "react-router-dom";
import AddRepoModal from "../components/AddRepoModal";
import TermsOfServicePage from "../components/TermsOfService";
import BillingPage from "../components/Billing";
import IntegrationsPage from "../components/IntegrationsOld";
import TeamPage from "../components/Team";
import DataSourcesPage from "../components/DataSourcesPage";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import AddBoardMappingModal from "../components/AddBoardMappingModal";
import AddDevopsBoardMappingModal from "../components/AddDevopsBoardMappingModal";
import AddStudioModal from "../components/AddStudioModal";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

const Settings = () => {
  const [boardMappings, setBoardMappings] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [devopsBoardMappings, setDevopsBoardMappings] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [availableRepos, setAvailableRepos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserFullName, setNewUserFullName] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [jiraUrl, setJiraUrl] = useState("");
  const [userMetadata, setUserMetadata] = useState({});
  const [isAddingAUser, setIsAddingAUser] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);
  const [linearMappings, setLinearMappings] = useState([]);
  const [studioOptions, setStudioOptions] = useState([]);
  const [studios, setStudios] = useState([])
  const [selectedLinearDataSource, setSelectedLinearDataSource] = useState(-1)
  const [linearURL, setLinearURL] = useState("");
  const linaerIntegrationCheck = linearMappings.some(mapping => mapping.oauth_token)


  const fetchBoardMappings = async () => {
    const {
      data: {
        session: { access_token },
      },
    } = await supabase.auth.getSession();
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      const response = await axios.get(`${API_URL}/get-board-mappings`, {
        headers,
      });
      setBoardMappings(response.data);
    } catch (error) {
      console.error("Failed to fetch board mappings:", error);
    }
  };

  const fetchTeams = async () => {
    const {
      data: {
        session: { access_token },
      },
    } = await supabase.auth.getSession();
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      const response = await axios.get(`${API_URL}/get-teams`, {
        headers,
      });
      setTeamMembers(response.data.teams[0].members);
      setTeamData(response.data.teams[0]);
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    }
  };

  const fetchDevopsBoardMappings = async () => {
    const {
      data: {
        session: { access_token },
      },
    } = await supabase.auth.getSession();
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      const response = await axios.get(`${API_URL}/get-devops-mappings`, {
        headers,
      });
      setDevopsBoardMappings(response.data);
    } catch (error) {
      console.error("Failed to fetch board mappings:", error);
    }
  };

  useEffect(() => {
    fetchBoardMappings();
    fetchTeams();
    fetchDevopsBoardMappings();
  }, []);

  const jira_data_columns = [
    { field: "repo_name", headerName: "Repository Name", width: 200 },
    { field: "board_key", headerName: "Board Key", width: 200 },
  ];

  const team_data_columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "full_name", headerName: "Full Name", width: 200 },
    { field: "role", headerName: "Role", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => handleRemoveUser(params.row)}
        >
          Remove
        </Button>
      ),
    },
  ];

  const handleRemoveUser = async (user) => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.post(
        `${API_URL}/remove-member-from-team`,
        {
          member_id: user.id,
          team_id: teamData.id,
        },
        {
          headers,
        }
      );
      await fetchTeams(); // Refresh the list of users
    } catch (error) {
      console.error(`Error removing user: ${user.email}`, error);
    }
  };

  const devops_data_columns = [
    { field: "repo_name", headerName: "Repository Name", width: 200 },
    { field: "tag", headerName: "Tag", width: 200 },
  ];

  const checkSlackIntegration = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.get(`${API_URL}/check-slack-integration`, {
        headers: headers,
      });
      setIsSlackIntegrated(response.data.slack_integration);
    } catch (error) {
      console.error("Failed to check Slack integration:", error);
    }
  };

  async function getAvailableStudios() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/studio/seeds`, {
        headers: headers,
      });

      if (response.data) {
        setStudios(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getLinearMappings = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.get(`${API_URL}/get-linear-mappings`, {
        headers: headers,
      });
      setLinearMappings(response.data);
    } catch (error) {
      console.error("Failed to check Slack integration:", error);
    }
  };

  async function getStudioOptions() {
    var studios = await getAvailableStudios();
    studios = studios.filter((studio) => studio.status === "READY");

    var options = [];

    var value = 1;

    for (let i = 0; i < studios.length; i++) {
      options.push({
        label: studios[i].display_name,
        value: value,
        type: "studio",
        data: studios[i],
      });
      value += 1;
    }

    setStudioOptions(options);
    return options;
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleJiraSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    event.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/update-jira-base-url`,
        {
          new_base_url: jiraUrl,
        },
        {
          headers: headers,
        }
      );
      setIsLoading(false);
      setJiraUrl(""); // Clear the input after successful update
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.error,
        severity: "error",
      });
    }
  };

  const handleLinearConnectSubmit = async() => {
    const authToken = (await supabase.auth.getSession()).data.session 
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.post(
        `${API_URL}/add-linear-mapping`,
        {
          linear_url: linearURL,
          studio_id: selectedLinearDataSource,
          team_id: userMetadata.team_id,
        },
        {
          headers,
        }
      );
      window.location.href =process.env.REACT_APP_LINEAR_OAUTH_URL;
    } catch (error) {
      console.error("Failed to check Slack integration:", error);
    }
  }

  const getRowId = (row) => `${row.board_key}-${row.repo_name}`;
  const getUserRowId = (row) => row.email;

  const getJiraBaseUrl = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/get-jira-base-url`, {
        headers: headers,
      });
      setJiraUrl(response.data.jira_base_url);
    } catch (error) {
      console.error("Failed to fetch Jira base URL:", error);
    }
  };

  const getUserMetadata = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/get-user-metadata`, {
        headers: headers,
      });
      setUserMetadata(response.data);
    } catch (error) {
      console.error("Failed to fetch Jira base URL:", error);
    }
  };

  const handleNewUserSubmit = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const userData = {
      email: newUserEmail,
      full_name: newUserFullName,
      password: newUserPassword,
      team_id: teamData.id,
    };
    try {
      const response = await axios.post(
        `${API_URL}/add-member-to-team`,
        userData,
        {
          headers,
        }
      );
      await fetchTeams(); // Refresh the list of users
      setIsLoading(false);
      setIsAddingAUser(false);
    } catch (error) {
      console.error(`Error adding user: ${newUserEmail}`, error);
    }
  };

  useEffect(() => {
    getJiraBaseUrl();
    checkSlackIntegration();
    getLinearMappings();
    getStudioOptions();
    getUserMetadata();
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const fetchUserDetails = async () => {
      const user = (await supabase.auth.getUser()).data.user;
      if (user && user.email) {
        setUserEmail(user.email);
      }
    };

    fetchUserDetails();
  }, []);

  const [boardMappingModalOpen, setBoardMappingModalOpen] = useState(false);
  const [devopsBoardMappingModalOpen, setDevopsBoardMappingModalOpen] =
    useState(false);
  const [studioModalOpen, setStudioModalOpen] = useState(false);

  const handleOpenBoardMappingModal = () => {
    setBoardMappingModalOpen(true);
  };

  const handleCloseBoardMappingModal = () => {
    setBoardMappingModalOpen(false);
  };

  useEffect(() => {
    // Redirects to the Stripe page when the "Subscriptions" tab is actively selected
    if (activeTab === 2) {
      // '2' here assumes that the tabs are indexed from 0, 1, 2, where 2 represents the Subscriptions tab
      const emailParam = encodeURIComponent(userEmail);
      const stripeUrl = `https://pay.fumedev.com/p/login/8wM5nl8Ltcuyego144?prefilled_email=${emailParam}`;
      window.open(stripeUrl, "_blank"); // '_blank' used to open in a new tab
      setActiveTab(0); // Reset the active tab to the default tab
    }
  }, [activeTab, userEmail]);

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "md",
          paddingY: 20,
          paddingX: 40,
          margin: "auto",
          backgroundColor: "background.paper",
          color: "text.primary",
        }}
      >
        <Box
          sx={{
            width: "200px",
            backgroundColor: "background.paper",
            p: 2,
          }}
        >
          <Button
            backgroundColor="background.primary"
            onClick={() => navigate("/")}
          >
            Back
          </Button>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Data" />
            <Tab label="Terms of Service" />
            <Tab label="Billing" />
            {userMetadata?.type == "root" && <Tab label="Integrations" />}
            {userMetadata?.type == "root" && <Tab label="Team" />}
          </Tabs>
        </Box>

        <Box sx={{ flex: 1, p: 3, maxWidth: "md" }}>
          {activeTab === 0 && <DataSourcesPage />}
          {activeTab === 1 && <TermsOfServicePage />}
          {activeTab === 2 && <BillingPage userEmail={userEmail} />}
          {activeTab === 3 && <IntegrationsPage />}
          {activeTab === 4 && <TeamPage />}
        </Box>
        <AddBoardMappingModal
          open={boardMappingModalOpen}
          onClose={handleCloseBoardMappingModal}
          repositories={availableRepos}
          baseUrl={jiraUrl}
        />
        <AddDevopsBoardMappingModal
          open={devopsBoardMappingModalOpen}
          onClose={() => setDevopsBoardMappingModalOpen(false)}
          repositories={availableRepos.filter(
            (repo) => repo.git_provider === "AZURE_DEVOPS"
          )}
        />
        <AddStudioModal
          open={studioModalOpen}
          onClose={() => setStudioModalOpen(false)}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default Settings;
