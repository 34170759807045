import React, { createContext, useContext, useState } from 'react';
import DataSourceSelector from '../components/DataSourceSelector';

const DataSourceContext = createContext();

export const useDataSource = () => useContext(DataSourceContext);

export const DataSourceProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onSelect, setOnSelect] = useState(null);

  const openDataSourceModal = (newOptions = [], onSelectCallback = null, replaceOptions = false) => {
    // Handle options update
    if (replaceOptions) {
      setOptions(newOptions);
    } else {
      setOptions(prevOptions => [...prevOptions, ...newOptions]);
    }

    // Always handle callback if provided
    if (onSelectCallback !== null) {
      setOnSelect(() => (selectedValue) => {
        const selectedOption = newOptions.find(opt => opt.value === selectedValue);
        return onSelectCallback(selectedValue, selectedOption);
      });
    }

    setIsModalOpen(true);
  };

  const closeDataSourceModal = () => {
    setIsModalOpen(false);
    setOptions([]);
    setLoading(false);
    setOnSelect(null);
  };

  return (
    <DataSourceContext.Provider value={{ openDataSourceModal, setLoading }}>
      {children}
      <DataSourceSelector
        open={isModalOpen}
        options={options}
        loading={loading}
        onSelect={onSelect}
        onClose={closeDataSourceModal}
      />
    </DataSourceContext.Provider>
  );
}; 