import React, { useRef, useEffect, useState } from 'react';
import { Box, Paper, Typography, Button, TextField, InputAdornment } from "@mui/material";
import { MousePointerClick, Keyboard, CornerDownLeft, MessageSquarePlus, KeyRound } from "lucide-react";
import axios from 'axios';
import { API_URL } from '../../../globals';
import { supabase } from '../../../supabaseClient';

const ActionCards = ({ actions, recordingId }) => {
  const bottomRef = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [customInstruction, setCustomInstruction] = useState('');
  const [localActions, setLocalActions] = useState(actions);

  useEffect(() => {
    setLocalActions(actions);
  }, [actions]);

  useEffect(() => {
    if (localActions.length > 0) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [localActions.length]);

  const handleSubmit = async (e) => {
    if (e.key === 'Enter' && customInstruction.trim()) {
      const newAction = {
        type: 'custom',
        details: {
          value: customInstruction.trim()
        },
        timestamp: new Date().toISOString()
      };

      setCustomInstruction('');
      setShowInput(false);
      
      setLocalActions(prev => [...prev, newAction]);
      
      try {
        const authToken = (await supabase.auth.getSession()).data.session.access_token;
        const headers = { Authorization: `Bearer ${authToken}` };

        await axios.post(`${API_URL}/studio/save_ui_action`, {
          recording_id: recordingId,
          action: newAction
        }, { headers });
      } catch (error) {
        console.error('Error saving custom instruction:', error);
        setLocalActions(actions);
        setShowInput(true);
        setCustomInstruction(newAction.details.value);
      }
    }
  };

  return (
    <Box sx={{ 
      height: '100%',
      backgroundColor: '#121212',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    }}>
      <Box sx={{ 
        overflowY: 'auto', 
        flexGrow: 1,
        padding: '16px',
        paddingBottom: '76px',
      }}>
        {localActions.length === 0 ? (
          <Box sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography variant="body1" color="text.secondary">
              Waiting for web actions...
            </Typography>
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            {localActions.filter(action => 
              action.type === 'click' || 
              action.type === 'input' || 
              action.type === 'custom' ||
              (action.type === 'specialKey' && action.details.key === 'Enter')
            ).map((action, index) => (
              <Paper
                key={index}
                sx={{
                  p: 2,
                  mb: 2,
                  backgroundColor: '#1e1e1e',
                  border: '1px solid rgba(255, 255, 255, 0.12)',
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                {action.type === 'click' ? (
                  <MousePointerClick size={20} color="#fff" />
                ) : action.type === 'custom' ? (
                  <MessageSquarePlus size={20} color="#fff" />
                ) : action.type === 'specialKey' ? (
                  <KeyRound size={20} color="#fff" />
                ) : (
                  <Keyboard size={20} color="#fff" />
                )}
                <Typography>
                  {action.type === 'click' 
                    ? `Clicked on a web element`
                    : action.type === 'specialKey'
                      ? `Pressed Enter`
                      : action.type === 'custom'
                        ? `Custom instruction: ${action.details.value}`
                        : `Typed: ${action.details.value}`}
                </Typography>
              </Paper>
            ))}
            <div ref={bottomRef} />
          </Box>
        )}
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '16px',
        backgroundColor: '#121212',
        borderTop: '1px solid rgba(255, 255, 255, 0.12)',
      }}>
        {showInput ? (
          <TextField
            fullWidth
            value={customInstruction}
            onChange={(e) => setCustomInstruction(e.target.value)}
            onKeyDown={handleSubmit}
            placeholder="Type your custom instruction..."
            variant="outlined"
            autoFocus
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#1e1e1e',
                height: '48px',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.12)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.2)',
                },
              },
              '& .MuiInputBase-input': {
                height: '20px',
                padding: '14px',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'rgba(255, 255, 255, 0.5)' }}>
                    <Typography variant="caption">Enter to Submit</Typography>
                    <CornerDownLeft size={16} />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setShowInput(true)}
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.12)',
              color: 'white',
              textTransform: 'none',
              height: '48px',
              '&:hover': {
                borderColor: 'rgba(255, 255, 255, 0.2)',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              },
            }}
          >
            Add custom instructions
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ActionCards; 