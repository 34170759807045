import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Search, ChevronUp } from 'lucide-react';

const CodeSearchCard = ({ 
  query,
  results,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showResults, setShowResults] = useState(!animate);
  const [currentResultIndex, setCurrentResultIndex] = useState(animate ? -1 : results.length - 1);
  const [isCollapsed, setIsCollapsed] = useState(externalCollapsed);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowResults(true);
      setCurrentResultIndex(results.length - 1);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowResults(true), 300);
    }, 1000);

    return () => clearTimeout(revealTimer);
  }, [animate, results.length]);

  useEffect(() => {
    setIsCollapsed(externalCollapsed);
  }, [externalCollapsed]);

  // Reveal results one by one
  useEffect(() => {
    if (!animate) return;
    
    if (showResults && currentResultIndex < results.length - 1) {
      const resultTimer = setTimeout(() => {
        setCurrentResultIndex(prev => prev + 1);
      }, 100);
      return () => clearTimeout(resultTimer);
    }
  }, [showResults, currentResultIndex, results.length, animate]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    } 

    setIsCollapsed(!isCollapsed);
  };

  const formatLineRange = (range) => {
    // Return empty string if range or both properties are missing
    if (!range || (range.start == null && range.end == null)) {
      return '';
    }
    
    // Handle case where only one value exists
    if (range.start == null || range.end == null) {
      return `line ${range.start ?? range.end}`;
    }

    if (range.start === range.end) {
      return `line ${range.start}`;
    }
    return `lines ${range.start}-${range.end}`;
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Search 
              size={20}
              style={{ 
                marginRight: '12px',
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component="span"
                sx={{
                  fontSize: '0.875rem',
                  color: '#666',
                  mr: 1,
                }}
              >
                Searched for
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontSize: '0.875rem',
                  fontFamily: 'monospace',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                }}
              >
                {query}
              </Typography>
            </Box>
          </Box>

          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {showResults && !isCollapsed && (
          <Box
            sx={{
              mt: 2,
              opacity: showResults ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              maxHeight: 420,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#0f0f0f',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#2a2a2a',
                borderRadius: '4px',
              },
              scrollbarWidth: 'thin',
              pr: 1,
              mr: -1,
            }}
          >
            {results.map((result, index) => (
              <Box
                key={index}
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  mb: 1,
                  opacity: index <= currentResultIndex ? 1 : 0,
                  transform: index <= currentResultIndex ? 'translateX(0)' : 'translateX(-10px)',
                  transition: animate ? 'all 0.3s ease-out' : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontFamily: 'monospace',
                    color: '#fff',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    direction: 'rtl',
                    textAlign: 'left',
                    flex: 1,
                    mr: 2,
                  }}
                >
                  {result.file_path}
                </Typography>
                {formatLineRange(result.lines) && (
                  <Typography
                    sx={{
                      fontSize: '0.75rem',
                      color: '#666',
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      ml: 2,
                    }}
                  >
                    {formatLineRange(result.lines)}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CodeSearchCard; 