import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import { colors } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from '@mui/icons-material/Menu';
import { PanelRightClose, PanelRightOpen, LayoutPanelLeft, Unplug, Laptop, User, BookOpenText } from 'lucide-react';
import { useSidebar } from '../contexts/SidebarContext';
import { useDataSource } from '../contexts/DataSourceContext';
import { supabase } from "../supabaseClient";
import axios from "axios";
import { API_URL } from "../globals";

export default function NavBar({
  user,
  taskHistory,
  lastVisitedChat,
  pos,
}) {
  const { isOpen, setIsOpen } = useSidebar();
  const navigate = useNavigate();
  const location = useLocation();
  const isStudioPage = location.pathname.startsWith('/studio/');
  const { openDataSourceModal, setLoading } = useDataSource();
  const [options, setOptions] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const handleNewTask = async () => {
    const onSelectCallback = async (selectedOptionValue, selectedOption) => {
      try {
        const authToken = (await supabase.auth.getSession()).data.session.access_token;
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const response = await axios.post(`${API_URL}/start_task`, null, {
          headers: headers,
        });

        if (response.data?.task_id) {
          if (selectedOption) {
            await axios.put(
              `${API_URL}/studio/select`,
              {
                task_id: response.data.task_id,
                studio_id: selectedOption.value,
              },
              { headers }
            );
          }
          
          navigate(`/chat/${response.data.task_id}`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Show modal immediately with loading state
    openDataSourceModal([], onSelectCallback);
    setLoading(true);

    // Load studios asynchronously
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const studiosResponse = await axios.get(`${API_URL}/studio/seeds`, {
        headers: headers,
      });

      if (studiosResponse.data) {
        const studios = studiosResponse.data.filter(studio => studio.status === "READY");
        const studioOptions = studios.map((studio) => ({
          label: studio.display_name,
          value: studio.id,
          type: "studio",
          data: studio,
        }));
        
        // Update modal with loaded options, keeping the original callback
        openDataSourceModal(studioOptions, onSelectCallback, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Drawer
        variant="permanent"
        onMouseEnter={() => !isOpen && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          width: isOpen || isHovered ? 240 : 64,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isOpen || isHovered ? 240 : 64,
            boxSizing: "border-box",
            transition: 'width 0.2s',
            overflowX: 'hidden',
            backgroundColor: '#121212',
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
          },
        }}
        open
      >
        <Box sx={{ 
          height: 56, 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: isOpen || isHovered ? 'flex-start' : 'center',
          pl: isOpen || isHovered ? 1 : 0,
          width: '100%',
          mb: 3
        }}>
          <IconButton 
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              m: 0,
              p: '8px',
              color: '#fff',
            }}
          >
            {isOpen || isHovered ? <PanelRightOpen size={20} /> : <PanelRightClose size={20} />}
          </IconButton>
        </Box>

        <List sx={{ flexGrow: 1 }}>
          {!isStudioPage && (
            <ListItem onClick={handleNewTask} sx={{ 
              justifyContent: isOpen || isHovered ? 'start' : 'center', 
              px: isOpen || isHovered ? 2 : 0,
              '& .MuiListItemText-primary': { color: '#fff' }
            }}>
              <ListItemIcon sx={{ 
                minWidth: isOpen || isHovered ? 56 : 'auto',
                mr: isOpen || isHovered ? 'auto' : 0,
                color: '#fff'
              }}>
                <AddIcon />
              </ListItemIcon>
              {(isOpen || isHovered) && <ListItemText primary="Start New" />}
            </ListItem>
          )}
          <ListItem onClick={() => navigate("/")} sx={{ 
            justifyContent: isOpen || isHovered ? 'start' : 'center', 
            px: isOpen || isHovered ? 2 : 0,
            '& .MuiListItemText-primary': { color: '#fff' }
          }}>
            <ListItemIcon sx={{ 
              minWidth: isOpen || isHovered ? 56 : 'auto',
              mr: isOpen || isHovered ? 'auto' : 0,
              color: '#fff'
            }}>
              <LayoutPanelLeft size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Home" />}
          </ListItem>
          <ListItem onClick={() => navigate("/studios")} sx={{ 
            justifyContent: isOpen || isHovered ? 'start' : 'center', 
            px: isOpen || isHovered ? 2 : 0,
            '& .MuiListItemText-primary': { color: '#fff' }
          }}>
            <ListItemIcon sx={{ 
              minWidth: isOpen || isHovered ? 56 : 'auto',
              mr: isOpen || isHovered ? 'auto' : 0,
              color: '#fff'
            }}>
              <Laptop size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Studios" />}
          </ListItem>
          <ListItem onClick={() => navigate("/playbooks")} sx={{ 
            justifyContent: isOpen || isHovered ? 'start' : 'center', 
            px: isOpen || isHovered ? 2 : 0,
            '& .MuiListItemText-primary': { color: '#fff' }
          }}>
            <ListItemIcon sx={{ 
              minWidth: isOpen || isHovered ? 56 : 'auto',
              mr: isOpen || isHovered ? 'auto' : 0,
              color: '#fff'
            }}>
              <BookOpenText size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Playbooks" />}
          </ListItem>
          <ListItem onClick={() => navigate("/integrations")} sx={{ 
            justifyContent: isOpen || isHovered ? 'start' : 'center', 
            px: isOpen || isHovered ? 2 : 0,
            '& .MuiListItemText-primary': { color: '#fff' }
          }}>
            <ListItemIcon sx={{ 
              minWidth: isOpen || isHovered ? 56 : 'auto',
              mr: isOpen || isHovered ? 'auto' : 0,
              color: '#fff'
            }}>
              <Unplug size={20} />
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary="Integrations" />}
          </ListItem>
        </List>

        <Box sx={{ mt: "auto", borderTop: "1px solid rgba(255, 255, 255, 0.12)", p: 1 }}>
          <ListItem 
            onClick={() => navigate("/profile")} 
            sx={{ 
              justifyContent: isOpen || isHovered ? 'start' : 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)'
              },
              '& .MuiListItemText-primary': { color: '#fff' }
            }}
          >
            <ListItemIcon sx={{ minWidth: isOpen || isHovered ? 56 : 'auto' }}>
              <Avatar 
                sx={{ 
                  bgcolor: colors.teal[200],
                  width: 32,
                  height: 32,
                  color: '#000'
                }}
              >
                {user?.full_name &&
                  user?.full_name
                    .split(" ")
                    .slice(0, 2)
                    .map((name) => name[0])}
              </Avatar>
            </ListItemIcon>
            {(isOpen || isHovered) && <ListItemText primary={user?.full_name} />}
          </ListItem>
        </Box>
      </Drawer>
    </>
  );
}