import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { FileEdit, ChevronUp } from 'lucide-react';

const EditCodeCard = ({ 
  filePath, 
  diff,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showContent, setShowContent] = useState(!animate);
  const [showFullPath, setShowFullPath] = useState(!animate);
  const [isCollapsed, setIsCollapsed] = useState(externalCollapsed);
  const fileName = filePath.split('/').pop();

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowFullPath(true);
      setShowContent(true);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowFullPath(true), 300);
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }, 1000);

    return () => clearTimeout(revealTimer);
  }, [animate]);

  useEffect(() => {
    setIsCollapsed(externalCollapsed);
  }, [externalCollapsed]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    }
    
    setIsCollapsed(!isCollapsed);
  };

  const renderDiffLine = (line) => {
    if (line.startsWith('@@')) {
      return (
        <div style={{
          backgroundColor: 'rgba(128, 128, 128, 0.2)',
          color: '#8b949e',
          borderRadius: '4px',
          marginBottom: '8px',
          marginTop: '8px',
          padding: '2px 0',
          fontFamily: 'monospace',
        }}>
          {line}
        </div>
      );
    }

    const lineContent = line.substring(1);
    const gutterStyle = {
      display: 'inline-block',
      width: '20px',
      marginRight: '10px',
      textAlign: 'center',
      userSelect: 'none',
      color: '#8b949e',
    };

    if (line.startsWith('+')) {
      return (
        <div style={{
          backgroundColor: 'rgba(35, 134, 54, 0.2)',
          color: '#2ea043',
          display: 'flex',
        }}>
          <span style={gutterStyle}>+</span>
          <span style={{ flex: 1 }}>{lineContent}</span>
        </div>
      );
    } else if (line.startsWith('-')) {
      return (
        <div style={{
          backgroundColor: 'rgba(248, 81, 73, 0.2)',
          color: '#f85149',
          display: 'flex',
        }}>
          <span style={gutterStyle}>-</span>
          <span style={{ flex: 1 }}>{lineContent}</span>
        </div>
      );
    } else {
      return (
        <div style={{
          display: 'flex',
        }}>
          <span style={gutterStyle}> </span>
          <span style={{ flex: 1 }}>{lineContent}</span>
        </div>
      );
    }
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          overflowY: 'auto',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          backdropFilter: isRevealing ? 'blur(4px)' : 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: isRevealing ? 'absolute' : 'relative',
            top: isRevealing ? '50%' : 0,
            left: isRevealing ? '50%' : 0,
            transform: isRevealing ? 'translate(-50%, -50%)' : 'none',
            transition: animate ? 'all 0.3s ease-out' : 'none',
            fontSize: isRevealing ? '0.85rem' : '0.60rem',
            mb: isRevealing ? 0 : 1,
            opacity: showContent ? 1 : 0.8,
            zIndex: 1,
            width: isRevealing ? 'auto' : '100%',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FileEdit 
              size={isRevealing ? 24 : 20}
              style={{ 
                marginRight: '8px',
                transition: animate ? 'all 0.3s ease-out' : 'none',
              }} 
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {showFullPath ? (
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  opacity: showFullPath ? 1 : 0,
                  transition: animate ? 'opacity 0.3s ease-in' : 'none',
                  fontSize: isRevealing ? '0.80rem' : '0.75rem',
                }}>
                  <Typography
                    component="span"
                    sx={{
                      color: '#666',
                      mr: 0.5,
                    }}
                  >
                    {filePath.substring(0, filePath.lastIndexOf('/'))}
                  </Typography>
                  <Typography 
                    component="span"
                    sx={{
                      color: '#ff9800',
                    }}
                  >
                    /{fileName}
                  </Typography>
                </Box>
              ) : (
                <Typography 
                  component="span"
                  sx={{
                    color: '#ff9800',
                  }}
                >
                  {fileName}
                </Typography>
              )}
            </Box>
          </Typography>

          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {!isCollapsed && (
          <Box 
            sx={{ 
              opacity: showContent ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              position: 'relative',
              zIndex: 1,
              maxHeight: 420,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#0f0f0f',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#2a2a2a',
                borderRadius: '4px',
              },
              scrollbarWidth: 'thin',
            }}
          >
            <pre style={{ 
              padding: '1rem',
              margin: 0,
              color: '#fff',
              fontSize: '0.75rem',
              backgroundColor: '#0f0f0f',
              borderRadius: 1,
              fontFamily: 'monospace',
            }}>
              <code>
                {diff.split('\n').map((line, index) => (
                  <div key={index} style={{ lineHeight: '1.5' }}>
                    {renderDiffLine(line)}
                  </div>
                ))}
              </code>
            </pre>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EditCodeCard; 