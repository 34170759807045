import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { supabase } from "../supabaseClient";
import axios from "axios";
import { API_URL, ON_PREM } from "../globals";
import { CircularProgress } from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: '#ffffff',
    },
    background: {
      default: '#121212',
      paper: '#121212',
    },
    text: {
      primary: '#ffffff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#555',
          },
          '&:hover fieldset': {
            borderColor: '#777',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#aaa',
          },
          color: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          textTransform: 'none',
          borderColor: '#555',
          color: '#fff',
          '&:hover': {
            borderColor: '#777',
            backgroundColor: '#1e1e1e',
          },
        },
      },
    },
  },
});

export default function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function generateCheckoutURL() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(
        `${API_URL}/stripe/generate_checkout_url`,
        {
          headers: headers,
        }
      );
      console.log(response);
      return response.data.url;
    } catch (error) {
      console.log(error);
    }
  }

  async function signUpNewUser(
    email,
    password,
    firstName,
    lastName,
    company,
    position
  ) {
    setLoading(true);
    try {
      let { data, error: signUpError } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            full_name: `${firstName} ${lastName}`,
            company: company,
            position: position,
          },
        },
      });


      if (signUpError) {
        console.log(signUpError);
        alert("Oops, something went wrong.");
        setLoading(false);
        return;
      }

          // Combine first name and last name into full name.
    let fullName = `${firstName} ${lastName}`;

    // Attempt to insert the user's details into the 'users' table.
    // Note: This operation uses the authenticated user's context automatically,
    // as the Supabase client library includes the user's access token in the request.
    let { data: insertData, error: insertError } = await supabase
      .from("users")
      .insert([
        {
          full_name: fullName,
          email: email,
          company: company,
          position: position,
        },
      ]);

    // If there's an error with the insert operation, log it and alert the user.
    if (insertError) {
      console.log(insertError);
      alert("Oops, something went wrong while saving your info.");
      return;
    }
    // Open the success modal
    if(!ON_PREM){
      let checkout_url = await generateCheckoutURL();
      // redirect to the checkout page
    window.location.href = checkout_url;
    }else{
      window.location.href = '/';
    }

    } catch (error) {
      console.error('Error:', error);
      alert('Error during signup process');
      setLoading(false);
    }
  }

  async function signUpWithGoogle() {
    setLoading(true);
    try {
      let { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin,
        },
      });

      if (error) {
        console.log(error);
        alert('Error signing up with Google.');
        setLoading(false);
        return;
      }

      // The user will be redirected to Google first, and then we'll need to handle
      // the Stripe checkout in a separate component after they return
      // You might want to create a new component to handle the OAuth return
      // and subsequent Stripe checkout
    } catch (error) {
      console.error('Error:', error);
      alert('Error during Google signup');
      setLoading(false);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signUpNewUser(
      data.get("email"),
      data.get("password"),
      data.get("firstName"),
      data.get("lastName"),
      data.get("company"),
      data.get("title")
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
            borderRadius: 2,
            border: '1px solid #333',
          }}
        >
          <Typography component="h1" variant="h5" sx={{ color: '#fff', mb: 2 }}>
            Create an account
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  InputLabelProps={{
                    style: { color: '#aaa' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  InputLabelProps={{
                    style: { color: '#aaa' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  InputLabelProps={{
                    style: { color: '#aaa' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  name="company"
                  required
                  fullWidth
                  id="company"
                  label="Company"
                  InputLabelProps={{
                    style: { color: '#aaa' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  InputLabelProps={{
                    style: { color: '#aaa' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  InputLabelProps={{
                    style: { color: '#aaa' },
                  }}
                />
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
                <Button
                  onClick={signUpWithGoogle}
                  fullWidth
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  sx={{
                    mt: 2,
                    mb: 2,
                  }}
                >
                  Sign up with Google
                </Button>
              </>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2" sx={{ color: '#777' }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
