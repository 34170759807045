import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  FormControl, 
  Select, 
  MenuItem, 
  Button,
  Modal,
  IconButton,
  CircularProgress
} from '@mui/material';
import { Laptop, X } from 'lucide-react';

const DataSourceSelector = ({ 
  open,
  options, 
  loading = false,
  onSelect,
  onClose,
}) => {
  const [selectedDataSource, setSelectedDataSource] = useState(
    options?.length > 0 ? options[0].value : ''
  );
  const [isProcessing, setIsProcessing] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedDataSource(event.target.value);
  };

  const confirmSelectedSource = async () => {
    if (onSelect) {
      setIsProcessing(true);
      try {
        await onSelect(selectedDataSource);
      } catch (error) {
        console.error(error);
      } finally {
        setIsProcessing(false);
      }
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      }}
    >
      <Box
        sx={{
          backgroundColor: '#121212',
          border: '1px solid #333',
          borderRadius: 2,
          p: 4,
          maxWidth: '500px',
          width: '90%',
          position: 'relative',
          outline: 'none',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#666',
          }}
        >
          <X size={20} />
        </IconButton>

        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Laptop size={24} style={{ color: '#666' }} />
          <Typography
            variant="h6"
            component="h2"
            sx={{
              color: '#fff',
              fontWeight: 600,
            }}
          >
            Select a Studio
          </Typography>
        </Box>

        <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} sx={{ color: '#fff' }} />
            </Box>
          ) : (
            <Select
              value={selectedDataSource}
              onChange={handleOptionChange}
              sx={{
                backgroundColor: '#121212',
                border: '1px solid #333',
                borderRadius: 1,
                color: '#fff',
                '& .MuiSelect-icon': {
                  color: '#666',
                },
                '&:hover': {
                  borderColor: '#666',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              {options.map((option, index) => (
                <MenuItem 
                  key={index} 
                  value={option.value}
                  sx={{
                    backgroundColor: '#121212',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#252525',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#252525',
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>

        {selectedDataSource > 0 && (
          <Button
            fullWidth
            variant="contained"
            onClick={confirmSelectedSource}
            disabled={isProcessing}
            sx={{
              backgroundColor: '#121212',
              border: '1px solid #333',
              borderRadius: 1,
              color: '#fff',
              py: 1.5,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#252525',
                borderColor: '#666',
              },
              '&.Mui-disabled': {
                backgroundColor: '#121212',
                color: '#fff',
                opacity: 0.7,
              },
            }}
          >
            {isProcessing ? (
              <CircularProgress size={20} sx={{ color: '#fff' }} />
            ) : (
              'Select Studio'
            )}
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default DataSourceSelector;