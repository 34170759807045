import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Divider,
} from "@mui/material";
import { ExternalLink, LogOut } from 'lucide-react';
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import axios from "axios";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  const handleManageSubscription = async () => {
    const emailParam = encodeURIComponent(user?.email);
    const stripeUrl = `https://pay.fumedev.com/p/login/8wM5nl8Ltcuyego144?prefilled_email=${emailParam}`;
    window.open(stripeUrl, '_blank'); // '_blank' used to open in a new tab
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('supabase.auth.token');
      localStorage.removeItem('supabase.auth.session');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/signin');
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Error logging out');
    }
  };

  if (isLoading) {
    return (
      <>
        <NavBar user={user} />
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#121212'
        }}>
          <CircularProgress size={32} sx={{ color: '#fff' }} />
        </Box>
      </>
    );
  }

  return (
    <>
      <NavBar user={user} />
      <Box sx={{ 
        minHeight: '100vh',
        pt: 8,
        px: 4,
        backgroundColor: '#121212',
      }}>
        <Box sx={{
          maxWidth: '800px',
          margin: '0 auto',
        }}>
          {/* Header */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 6
          }}>
            <Box>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600, 
                  color: '#fff',
                  mb: 1
                }}
              >
                Profile
              </Typography>
              <Typography sx={{ color: '#888', fontSize: '0.875rem' }}>
                Manage your account settings and subscription
              </Typography>
            </Box>
          </Box>

          {/* Profile Content */}
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {/* Name Section */}
              <Box>
                <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                  NAME
                </Typography>
                <Typography sx={{ color: '#fff', fontSize: '0.875rem' }}>
                  {user?.full_name || 'N/A'}
                </Typography>
              </Box>

              {/* Email Section */}
              <Box>
                <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                  EMAIL
                </Typography>
                <Typography sx={{ color: '#fff', fontSize: '0.875rem' }}>
                  {user?.email || 'N/A'}
                </Typography>
              </Box>

              {/* Subscription Section */}
              <Box>
                <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                  SUBSCRIPTION
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Chip
                    label={user?.subscription_tier || 'PRO'}
                    size="small"
                    sx={{
                      backgroundColor: '#252525',
                      color: '#fff',
                      fontSize: '0.75rem',
                      height: '24px',
                    }}
                  />
                  <Button
                    onClick={handleManageSubscription}
                    startIcon={<ExternalLink size={16} />}
                    sx={{
                      border: '1px solid #333',
                      color: '#fff',
                      textTransform: 'none',
                      fontSize: '0.875rem',
                      px: 2,
                      '&:hover': {
                        backgroundColor: '#252525',
                      },
                    }}
                  >
                    Manage Subscription
                  </Button>
                </Box>
              </Box>

              {/* Add Divider */}
              <Divider sx={{ borderColor: '#2a2a2a', my: 2 }} />

              {/* Logout Section */}
              <Box>
                <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 2 }}>
                  ACCOUNT
                </Typography>
                <Button
                  onClick={handleLogout}
                  startIcon={<LogOut size={16} />}
                  sx={{
                    border: '1px solid #333',
                    color: '#ff4444',
                    textTransform: 'none',
                    fontSize: '0.875rem',
                    px: 2,
                    '&:hover': {
                      backgroundColor: '#ff44441a',
                      borderColor: '#ff4444',
                    },
                  }}
                >
                  Log out
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile; 