import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { HelpCircle, ChevronUp } from 'lucide-react';

const QuestionCard = ({ 
  question, 
  answer,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showQuestion, setShowQuestion] = useState(!animate);
  const [showAnswer, setShowAnswer] = useState(!animate);
  const [typedQuestion, setTypedQuestion] = useState(animate ? '' : question);
  const [typedAnswer, setTypedAnswer] = useState(animate ? '' : answer);
  const [cursorVisible, setCursorVisible] = useState(animate);
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowQuestion(true);
      setShowAnswer(true);
      setCursorVisible(false);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowQuestion(true), 300);
    }, 1000);

    // Cursor blink effect
    const cursorInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);

    return () => {
      clearTimeout(revealTimer);
      clearInterval(cursorInterval);
    };
  }, [animate]);

  // Type question effect
  useEffect(() => {
    if (!animate) return;

    if (showQuestion && typedQuestion.length < question.length) {
      const typeTimer = setTimeout(() => {
        setTypedQuestion(question.slice(0, typedQuestion.length + 1));
      }, 50);
      return () => clearTimeout(typeTimer);
    } else if (showQuestion && typedQuestion.length === question.length) {
      setTimeout(() => {
        setShowAnswer(true);
      }, 500);
    }
  }, [showQuestion, typedQuestion, question, animate]);

  // Type answer effect
  useEffect(() => {
    if (!animate) return;

    if (showAnswer && typedAnswer.length < answer.length) {
      const typeTimer = setTimeout(() => {
        setTypedAnswer(answer.slice(0, typedAnswer.length + 1));
      }, 30);
      return () => clearTimeout(typeTimer);
    }
  }, [showAnswer, typedAnswer, answer, animate]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HelpCircle 
              size={20}
              style={{ 
                marginRight: '12px',
                color: '#4f8cc9',
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component="span"
                sx={{
                  fontSize: '0.875rem',
                  mr: 1,
                  color: '#666',
                }}
              >
                Search Web
              </Typography>
              <Typography
                component="span"
                sx={{
                  color: '#4f8cc9',
                  fontSize: '0.875rem',
                  fontFamily: 'monospace',
                  backgroundColor: 'rgba(79, 140, 201, 0.1)',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  minWidth: '20px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {typedQuestion}
                {cursorVisible && showQuestion && !showAnswer && (
                  <span style={{ opacity: cursorVisible ? 1 : 0 }}>▋</span>
                )}
              </Typography>
            </Box>
          </Box>

          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: !isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {showAnswer && (
          <Box
            sx={{
              mt: 2,
              opacity: showAnswer ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              maxHeight: isCollapsed ? 0 : 420,
              overflowY: 'auto',
              visibility: isCollapsed ? 'hidden' : 'visible',
            }}
          >
            <Typography
              sx={{
                color: '#b392f0',
                fontSize: '0.875rem',
                whiteSpace: 'pre-wrap',
                fontFamily: 'monospace',
                backgroundColor: 'rgba(179, 146, 240, 0.1)',
                p: 2,
                borderRadius: 1,
                position: 'relative',
              }}
            >
              {typedAnswer}
              {cursorVisible && showAnswer && typedAnswer.length < answer.length && animate && (
                <span style={{ opacity: cursorVisible ? 1 : 0 }}>▋</span>
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QuestionCard; 