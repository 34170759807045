import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import Loading from '../pages/Loading';

function ProtectedRoute({ element: Component, ...rest }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      const { data: authUser } = await supabase.auth.getUser();

      // If specific user, avoid setting user and navigate to signin
      if (authUser.user && authUser.user.email === "sandbox@fumedev.com") {
        setLoading(false); // Ensure loading is always set to false
        return;
      }

      if (authUser.user) {
        setUser(authUser.user); // Set the user if not the specific blocked email
      }
      setLoading(false); // Ensure loading is always set to false
    };

    getUser();
  }, []);

  if (loading) {
    // Render a loading state while fetching the user information
    return <Loading />;
  }

  // Navigate to signin if no user or blocked user
  return user ? <Component {...rest} /> : <Navigate to="/signin" replace />;
}

export default ProtectedRoute;
