import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Globe } from 'lucide-react';
import { GoTrueClient } from '@supabase/supabase-js';

const GoToUrlCard = ({ url, animate = true }) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [typedUrl, setTypedUrl] = useState(animate ? '' : url);
  const [cursorVisible, setCursorVisible] = useState(animate);
  const [isClicked, setIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(!animate);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setCursorVisible(false);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
    }, 1000);

    // Cursor blink effect
    const cursorInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);

    return () => {
      clearTimeout(revealTimer);
      clearInterval(cursorInterval);
    };
  }, [animate]);

  // Type URL effect
  useEffect(() => {
    if (!animate) return;

    if (!isRevealing && typedUrl.length < url.length) {
      const typeTimer = setTimeout(() => {
        setTypedUrl(url.slice(0, typedUrl.length + 1));
      }, 50);
      return () => clearTimeout(typeTimer);
    } else if (!isRevealing && typedUrl.length === url.length) {
      // Start click and loading animations after typing
      setTimeout(() => {
        setIsClicked(true);
        setTimeout(() => {
          setIsClicked(false);
          setIsLoading(true);
        }, 150);
      }, 500);
    }
  }, [isRevealing, typedUrl, url, animate]);

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: '60px',
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          overflow: 'hidden', // For loading bar
        }}
      >
        {/* Loading bar */}
        {isLoading && animate && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              animation: 'loadingBar 2s ease-in-out forwards',
              '@keyframes loadingBar': {
                '0%': {
                  background: 'linear-gradient(90deg, transparent 0%, rgba(79, 140, 201, 0.1) 50%, transparent 100%)',
                  transform: 'translateX(-100%)',
                },
                '100%': {
                  background: 'linear-gradient(90deg, transparent 0%, rgba(79, 140, 201, 0.1) 50%, transparent 100%)',
                  transform: 'translateX(100%)',
                },
              },
            }}
          />
        )}
        
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Globe 
            size={20}
            style={{ 
              marginRight: '12px',
              color: '#4f8cc9',
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '0.875rem',
                mr: 1,
              }}
            >
              Navigated to
            </Typography>
            <Typography
              component="span"
              sx={{
                color: '#4f8cc9',
                fontSize: '0.875rem',
                fontFamily: 'monospace',
                backgroundColor: 'rgba(79, 140, 201, 0.1)',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                minWidth: '20px',
                display: 'inline-flex',
                alignItems: 'center',
                transform: isClicked ? 'scale(0.95)' : 'scale(1)',
                transition: animate ? 'transform 0.15s ease-in-out' : 'none',
                maxWidth: '400px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {typedUrl}
              {cursorVisible && typedUrl.length < url.length && (
                <span style={{ opacity: cursorVisible ? 1 : 0 }}>▋</span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoToUrlCard; 