import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { CheckCircle } from 'lucide-react';

const StepCompletionCard = ({ 
  stepDescription, 
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false 
}) => {
  const [isRevealing, setIsRevealing] = useState(true);
  const [internalCollapsed, setInternalCollapsed] = useState(defaultCollapsed);

  const isCollapsed = externalCollapsed !== undefined && !internalCollapsed ? externalCollapsed : internalCollapsed;

  useEffect(() => {
    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
    }, 1000);

    return () => clearTimeout(revealTimer);
  }, []);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    } else {
      setInternalCollapsed(!isCollapsed);
    }
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isCollapsed ? '60px' : 'auto',
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: 'all 0.3s ease-out',
          animation: 'fadeIn 0.3s ease-in',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
          },
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: 'all 0.3s ease-out',
          }}
        >
          <CheckCircle 
            size={20}
            style={{ 
              marginRight: '12px',
              color: '#4caf50', // Green color to indicate completion
            }}
          />
          <Typography
            component="span"
            sx={{
              fontSize: '0.875rem',
            }}
          >
            {stepDescription ? stepDescription : "Step completed"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StepCompletionCard; 