import React from 'react';
import { Box, Typography } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const TermsOfServicePage = ({ numPages, onDocumentLoadSuccess }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Terms of Service
      </Typography>
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <Document
          file="/terms.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </Box>
    </Box>
  );
};

export default TermsOfServicePage;
