import React, { useEffect } from 'react';
import { Box, Typography } from "@mui/material";

const BillingPage = ({ userEmail }) => {
  useEffect(() => {
    if (userEmail) {
      const emailParam = encodeURIComponent(userEmail);
      const stripeUrl = `https://pay.fumedev.com/p/login/8wM5nl8Ltcuyego144?prefilled_email=${emailParam}`;
      window.open(stripeUrl, "_blank");
    }
  }, [userEmail]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Billing
      </Typography>
      <Typography>
        You will be redirected to the billing page.
      </Typography>
    </Box>
  );
};

export default BillingPage;
