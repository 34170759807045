import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Chip,
} from '@mui/material';

const IntegrationCard = ({ integration, onClick }) => {
  const isEnterprise = integration.isEnterprise;

  return (
    <Box
      onClick={isEnterprise ? undefined : onClick}
      sx={{
        position: 'relative',
        backgroundColor: '#1a1a1a',
        borderRadius: 1,
        border: '1px solid #2a2a2a',
        cursor: isEnterprise ? 'default' : 'pointer',
        transition: 'all 0.2s ease',
        filter: isEnterprise ? 'brightness(0.7)' : 'none',
        '&:hover': {
          backgroundColor: isEnterprise ? '#1a1a1a' : '#202020',
          borderColor: isEnterprise ? '#2a2a2a' : '#333',
          transform: isEnterprise ? 'none' : 'translateY(-2px)',
        },
      }}
    >
      {isEnterprise && (
        <Chip
          label="Enterprise Tier Only"
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '14px',
            fontSize: '0.75rem',
            fontWeight: 500,
            backdropFilter: 'blur(4px)',
            border: '1px solid rgba(255, 255, 255, 0.2)',
          }}
        />
      )}
      <Box sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
          <Box sx={{
            backgroundColor: '#252525',
            p: 1,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: integration.color,
          }}>
            {integration.icon}
          </Box>
          <Typography sx={{ 
            fontSize: '1rem',
            fontWeight: 500,
            color: '#fff'
          }}>
            {integration.name}
          </Typography>
        </Box>
        
        <Typography sx={{ 
          color: '#888',
          fontSize: '0.875rem',
          lineHeight: 1.5
        }}>
          {integration.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default IntegrationCard; 