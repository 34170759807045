import React from 'react';
import styled from 'styled-components';
import ChatIcon from '@mui/icons-material/Chat';
import ComputerIcon from '@mui/icons-material/Computer';

const SwitchContainer = styled.div`
  display: flex;
  background-color: rgba(30, 30, 30, 0.5);
  border-radius: 8px;
  padding: 4px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: all 0.2s ease;
  backdrop-filter: blur(8px);
  pointer-events: auto;

  &:hover {
    background-color: rgba(30, 30, 30, 1);
  }
`;

const TabButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: ${props => props.active ? 'rgba(66, 66, 66, 0.8)' : 'transparent'};
  border: none;
  border-radius: 6px;
  color: ${props => props.active ? '#fff' : 'rgba(255, 255, 255, 0.7)'};
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background-color: ${props => props.active ? 'rgba(66, 66, 66, 1)' : 'rgba(42, 42, 42, 0.8)'};
  }

  svg {
    font-size: 20px;
    opacity: ${props => props.active ? 1 : 0.7};
    transition: opacity 0.2s ease;
  }

  &:hover svg {
    opacity: 1;
  }
`;

const NotificationDot = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #8FB3B0;
  position: absolute;
  top: 4px;
  right: 4px;
`;

const FloatingTabSwitch = ({ activeTab, setActiveTab, tabWithNotification = null }) => {
  return (
    <SwitchContainer>
      <TabButton
        active={activeTab === 'Chat'}
        onClick={() => setActiveTab('Chat')}
      >
        <ChatIcon />
        {tabWithNotification === 'Chat' && <NotificationDot />}
      </TabButton>
      <TabButton
        active={activeTab === 'Studio'}
        onClick={() => setActiveTab('Studio')}
      >
        <ComputerIcon />
        {tabWithNotification === 'Studio' && <NotificationDot />}
      </TabButton>
    </SwitchContainer>
  );
};

export default FloatingTabSwitch;