import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { Github, Gitlab, FileCode, kanban, Kanban, Slack } from 'lucide-react';
import NavBar from "../components/NavBar";
import IntegrationCard from "../components/IntegrationCard";
import GithubModal from "../components/IntegrationModals/GithubModal";
import GitlabModal from "../components/IntegrationModals/GitlabModal";
import AzureDevopsModal from "../components/IntegrationModals/AzureDevopsModal";
import JiraModal from "../components/IntegrationModals/JiraModal";
import SlackModal from "../components/IntegrationModals/SlackModal";
import LinearModal from "../components/IntegrationModals/LinearModal";
import azureIcon from '../assets/azure-devops.png';
import jiraIcon from '../assets/jira.png';
import linearIcon from '../assets/linear.png';
import bitbucketIcon from '../assets/bitbucket.png';

const Integrations = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [user, setUser] = useState(null);

  const integrations = [
    {
      id: 'github',
      name: 'GitHub',
      description: 'Connect your GitHub organization to enable Fume to publish pull requests.',
      icon: <Github size={24} />,
      color: '#ffff'
    },
    {
        id: 'slack',
        name: 'Slack',
        description: 'Connect to Slack so Fume can ping you when it needs your help.',
        icon: <Slack size={24} />,
        color: '#ffff'
    },
    {
        id: 'linear',
        name: 'Linear',
        description: 'Connect your Linear organization to assign issues to Fume.',
        icon: <img src={linearIcon} alt="Linear" width={24} height={24} />,
        color: '#ffff'
    },
    {
      id: 'gitlab',
      name: 'GitLab',
      description: 'Connect your GitLab organization to enable Fume to publish merge requests.',
      icon: <Gitlab size={24} />,
      color: '#ffff',
      isEnterprise: true
    },
    {
      id: 'azure',
      name: 'Azure DevOps',
      description: 'Connect your Azure DevOps organization to enable Fume to publish pull requests.',
      icon: <img src={azureIcon} alt="Azure DevOps" width={24} height={24} />,
      color: '#ffff',
      isEnterprise: true
    },
    {
      id: 'jira',
      name: 'Jira',
      description: 'Connect your Jira dashboard to assign issues to Fume right from your project dashboard.',
      icon: <img src={jiraIcon} alt="Jira" width={24} height={24} />,
      color: '#ffff',
      isEnterprise: true
    },
    {
      id: 'bitbucket',
      name: 'Bitbucket',
      description: 'Connect your Bitbucket organization to enable Fume to publish pull requests.',
      icon: <img src={bitbucketIcon} alt="Bitbucket" width={24} height={24} />,
      color: '#ffff',
      isEnterprise: true
    }
  ];

  const handleOpenModal = (integrationId) => {
    setActiveModal(integrationId);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  return (
    <>
      <NavBar user={user} />
      <Box sx={{ 
        minHeight: '100vh',
        pt: 8,
        px: 4,
        backgroundColor: '#121212',
      }}>
        <Box sx={{
          maxWidth: '1200px',
          margin: '0 auto',
        }}>
          {/* Header */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 6
          }}>
            <Box>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600, 
                  color: '#fff',
                  mb: 1
                }}
              >
                Integrations
              </Typography>
              <Typography sx={{ color: '#888', fontSize: '0.875rem' }}>
                Connect Fume to your development tools and services
              </Typography>
            </Box>
          </Box>

          {/* Integration Cards Grid */}
          <Grid container spacing={3}>
            {integrations.map((integration) => (
              <Grid item xs={12} sm={6} md={4} key={integration.id}>
                <IntegrationCard
                  integration={integration}
                  onClick={() => handleOpenModal(integration.id)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Integration Modals */}
        <GithubModal 
          open={activeModal === 'github'} 
          onClose={handleCloseModal} 
        />
        <GitlabModal 
          open={activeModal === 'gitlab'} 
          onClose={handleCloseModal} 
        />
        <AzureDevopsModal 
          open={activeModal === 'azure'} 
          onClose={handleCloseModal} 
        />
        <JiraModal 
          open={activeModal === 'jira'} 
          onClose={handleCloseModal} 
        />
      </Box>
      <SlackModal 
        open={activeModal === 'slack'} 
        onClose={handleCloseModal} 
      />
      <LinearModal 
        open={activeModal === 'linear'} 
        onClose={handleCloseModal} 
      />
    </>
  );
};

export default Integrations; 