import React, { useRef, useState, useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import ChatInput from './ChatInput';
import MemoizedMessageCard from './MemoizedMessageCard';
import { useSidebar } from '../contexts/SidebarContext';

const ChatContainer = ({
  task,
  messages,
  liveResponse,
  isLoading,
  planProposed,
  sendMessage,
  approvePlan,
  rejectPlan,
  stopTask,
  bottomRef,
  message,
  setMessage,
  statusMessage,
  attachments,
  onUpdateAttachments,
}) => {
  const { isOpen } = useSidebar();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const scrollContainerRef = useRef(null);
  const [stickToBottom, setStickToBottom] = useState(true);

  const containerStyles = {
    maxWidth: '800px',
    width: '100%',
    margin: '0 auto',
    backgroundColor: 'transparent',
    padding: '0 16px',
  };

  const handleToggleCollapse = (messageId) => {
    console.log('Toggle collapse for message:', messageId);
  };

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;
    
    const { scrollHeight, scrollTop, clientHeight } = scrollContainerRef.current;
    const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 10;
    
    setStickToBottom(isAtBottom);
  };

  useEffect(() => {
    if (stickToBottom && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, liveResponse, stickToBottom]);

  return (
    <Box 
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'transparent',
        pt: '64px',
        position: 'relative',
        width: '100%',
      }}
    >
      <Box 
        ref={scrollContainerRef}
        onScroll={handleScroll}
        sx={{
          ...containerStyles,
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          pb: '100px',
          mt: '-64px',
          height: '100vh',
          '& > *': {
            maxWidth: '100%',
            wordWrap: 'break-word',
          }
        }}
      >
        <Box sx={{ height: '100px', flexShrink: 0 }} />
        {messages.map((msg, index) => (
          <MemoizedMessageCard
            key={msg.id}
            message={msg}
            index={index}
            totalMessages={messages.length}
            onToggleCollapse={handleToggleCollapse}
          />
        ))}
        {liveResponse && (
          <MemoizedMessageCard
            message={liveResponse}
            index={messages.length}
            totalMessages={messages.length + 1}
            onToggleCollapse={handleToggleCollapse}
          />
        )}
        <div ref={bottomRef} />
        <Box sx={{ height: '400px', flexShrink: 0 }} />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          width: '100%',
          zIndex: 10,
        }}
      >
        <Box sx={{ ...containerStyles }}>
          <ChatInput
            onSendMessage={sendMessage}
            isLoading={isLoading}
            onStopGeneration={stopTask}
            message={message}
            setMessage={setMessage}
            status={statusMessage}
            attachments={attachments}
            onUpdateAttachments={onUpdateAttachments}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatContainer; 