import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../CodeBlock';

const MessageCard = ({ message, isLast }) => {
  const renderContent = () => {
    switch (message.content.type) {
      case 'chat_message':
        const messageContent = typeof message.content.data === 'string' 
          ? message.content.data 
          : JSON.stringify(message.content.data, null, 2);
          
        return (
          <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
            <ReactMarkdown
              components={{
                code: ({ node, inline, className, children, ...props }) => {
                  if (inline) {
                    return <code className={className}>{children}</code>;
                  }
                  return <CodeBlock className={className}>{children}</CodeBlock>;
                },
              }}
            >
              {messageContent}
            </ReactMarkdown>
          </Typography>
        );

      case 'code_diff':
        return (
          <Box sx={{ backgroundColor: '#1e1e1e', p: 2, borderRadius: 1 }}>
            <Typography sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', fontSize: '0.875rem' }}>
              {message.content.data}
            </Typography>
          </Box>
        );

      case 'file_tree':
        return (
          <Box sx={{ backgroundColor: '#2a2a2a', p: 2, borderRadius: 1 }}>
            <Typography sx={{ fontFamily: 'monospace', fontSize: '0.875rem' }}>
              {JSON.stringify(message.content.data, null, 2)}
            </Typography>
          </Box>
        );

      case 'error':
        return (
          <Box sx={{ backgroundColor: '#442222', p: 2, borderRadius: 1 }}>
            <Typography color="error" sx={{ fontSize: '0.875rem' }}>
              Error: {message.content.data}
            </Typography>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
          width: '0',
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '-ms-overflow-style': 'none',
        overflow: '-moz-scrollbars-none'
      }}
    >
      <Typography variant="subtitle1" gutterBottom sx={{ color: '#fff', fontWeight: 600, mt: 3 }}>
        {message.role === "user" ? "You" : "Fume"}
      </Typography>
      <Box
        sx={{
          p: 2,
          borderRadius: 2,
          animation: isLast ? "blink 2s infinite" : "none",
          color: '#fff',
          maxWidth: '100%',
          overflowY: 'auto',
          '& pre': {
            maxWidth: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
              width: '0',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '-ms-overflow-style': 'none',
            overflow: '-moz-scrollbars-none'
          },
          '&::-webkit-scrollbar': {
            display: 'none',
            width: '0',
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '-ms-overflow-style': 'none',
          overflow: '-moz-scrollbars-none'
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default MessageCard; 