import React from 'react';
import { Box, Typography } from '@mui/material';
import { MousePointerClick, BookOpen } from 'lucide-react';

const MessageAttachments = ({ attachments }) => {
  if (!attachments || attachments.length === 0) return null;

  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      flexWrap: 'wrap',
      mt: 2,
    }}>
      {attachments.map((attachment) => (
        <Box
          key={attachment.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: 'transparent',
            border: '1px solid #333',
            borderRadius: 1,
            px: 2,
            py: 0.5,
          }}
        >
          {attachment.type === 'Web Flow' ? (
            <MousePointerClick size={14} style={{ color: '#666' }} />
          ) : attachment.type === 'Markdown' && (
            <BookOpen size={14} style={{ color: '#666' }} />
          )}
          <Typography 
            sx={{ 
              color: '#fff', 
              fontSize: '0.875rem',
              maxWidth: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {attachment.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MessageAttachments; 