import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Keyboard } from 'lucide-react';

const TypeInputCard = ({ text, animate = true }) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [typedText, setTypedText] = useState(animate ? '' : text);
  const [cursorVisible, setCursorVisible] = useState(animate);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setCursorVisible(false);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
    }, 1000);

    // Cursor blink effect
    const cursorInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);

    return () => {
      clearTimeout(revealTimer);
      clearInterval(cursorInterval);
    };
  }, [animate]);

  // Type text effect
  useEffect(() => {
    if (!animate) return;

    if (!isRevealing && typedText.length < text.length) {
      const typeTimer = setTimeout(() => {
        setTypedText(text.slice(0, typedText.length + 1));
      }, 50);
      return () => clearTimeout(typeTimer);
    }
  }, [isRevealing, typedText, text, animate]);

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: '60px',
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Keyboard 
            size={20}
            style={{ 
              marginRight: '12px',
              color: '#4f8cc9',
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '0.875rem',
                mr: 1,
              }}
            >
              Typed
            </Typography>
            <Typography
              component="span"
              sx={{
                color: '#4f8cc9',
                fontSize: '0.875rem',
                fontFamily: 'monospace',
                backgroundColor: 'rgba(79, 140, 201, 0.1)',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                minWidth: '20px',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              {typedText}
              {cursorVisible && typedText.length < text.length && (
                <span style={{ opacity: cursorVisible ? 1 : 0 }}>▋</span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TypeInputCard; 