import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { FilePlus, ChevronUp } from 'lucide-react';

const FileCreationCard = ({ 
  filePath, 
  content,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [lines, setLines] = useState([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(animate ? -1 : content.split('\n').length - 1);
  const [isComplete, setIsComplete] = useState(!animate);
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showFullPath, setShowFullPath] = useState(!animate);
  const fileName = filePath.split('/').pop();
  
  const [isCollapsed, setIsCollapsed] = useState(externalCollapsed);
  const preRef = React.useRef(null);

  useEffect(() => {
    // Reset states when content changes
    if (animate) {
      setIsComplete(false);
      setCurrentLineIndex(-1);
    } else {
      setIsComplete(true);
      setCurrentLineIndex(content.split('\n').length - 1);
    }
    
    // Split content into lines and initialize
    const contentLines = content.split('\n');
    setLines(contentLines);
    
    if (!animate) {
      setIsRevealing(false);
      setShowFullPath(true);
      return;
    }

    // Initial reveal animation
    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowFullPath(true), 300);
    }, 1000);
    
    let currentIndex = -1;
    
    // Delay the line streaming until after the filename animation
    const streamTimer = setTimeout(() => {
      const interval = setInterval(() => {
        if (currentIndex >= contentLines.length - 1) {
          clearInterval(interval);
          setTimeout(() => setIsComplete(true), 800);
          return;
        }
        currentIndex++;
        setCurrentLineIndex(currentIndex);
        
        // Auto-scroll to the current line
        if (preRef.current) {
          const lineElement = preRef.current.children[0].children[currentIndex];
          if (lineElement) {
            lineElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }
      }, 200);

      return () => clearInterval(interval);
    }, 1500);

    return () => {
      clearTimeout(revealTimer);
      clearTimeout(streamTimer);
    };
  }, [content, animate]);

  useEffect(() => {
    setIsCollapsed(externalCollapsed);
  }, [externalCollapsed]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          overflowY: 'hidden',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: isRevealing ? 'absolute' : 'relative',
            top: isRevealing ? '50%' : 0,
            left: isRevealing ? '50%' : 0,
            transform: isRevealing ? 'translate(-50%, -50%)' : 'none',
            transition: animate ? 'all 0.3s ease-out' : 'none',
            fontSize: isRevealing ? '0.8rem' : '0.7rem',
            mb: isRevealing ? 0 : 1,
            opacity: 1,
            zIndex: 1,
            width: isRevealing ? 'auto' : '100%',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FilePlus 
              size={isRevealing ? 24 : 20}
              style={{ 
                marginRight: '8px',
                transition: animate ? 'all 0.3s ease-out' : 'none',
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {showFullPath ? (
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  opacity: showFullPath ? 1 : 0,
                  transition: animate ? 'opacity 0.3s ease-in' : 'none',
                }}>
                  <Typography
                    component="span"
                    sx={{
                      color: '#666',
                      mr: 0.5,
                      fontSize: '0.85rem',
                    }}
                  >
                    {filePath.substring(0, filePath.lastIndexOf('/'))}
                  </Typography>
                  <Typography 
                    component="span"
                    sx={{
                      color: 'rgb(35, 134, 54)',
                    }}
                  >
                    /{fileName}
                  </Typography>
                </Box>
              ) : (
                <Typography 
                  component="span"
                  sx={{
                    color: 'rgb(35, 134, 54)',
                  }}
                >
                  {fileName}
                </Typography>
              )}
            </Box>
          </Typography>

          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {!isCollapsed && !isRevealing && (
          <Box 
            sx={{ 
              opacity: !isRevealing ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              position: 'relative',
              zIndex: 1,
              maxHeight: 'calc(500px - 4rem)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#0f0f0f',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#2a2a2a',
                borderRadius: '4px',
              },
              scrollbarWidth: 'thin',
            }}
          >
            <pre 
              ref={preRef}
              style={{ 
                padding: '1rem',
                margin: 0,
                color: '#fff',
                fontSize: '0.7rem',
              }}
            >
              <code>
                {lines.map((line, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: !isComplete && index === currentLineIndex 
                        ? 'rgba(35, 134, 54, 0.6)'
                        : 'transparent',
                      opacity: index <= currentLineIndex ? 1 : 0,
                      transition: animate ? 'opacity 0.3s ease-in' : 'none',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                      marginLeft: '-4px',
                      marginRight: '-4px',
                      borderRadius: '2px',
                      lineHeight: '1.5',
                      width: '100%',
                    }}
                  >
                    {line}
                  </div>
                ))}
              </code>
            </pre>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileCreationCard; 