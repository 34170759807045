import { createContext, useContext, useState } from 'react';

const LastChatContext = createContext();

export const useLastChat = () => {
  const context = useContext(LastChatContext);
  if (!context) {
    throw new Error(`useLastChat must be used within a LastChatProvider`);
  }
  return context;
};

export const LastChatProvider = ({ children }) => {
  const [lastVisitedChat, setLastVisitedChat] = useState('');

  return (
    <LastChatContext.Provider value={{ lastVisitedChat, setLastVisitedChat }}>
      {children}
    </LastChatContext.Provider>
  );
};