import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ReactMarkdown from 'react-markdown';
import CodeBlock from "./CodeBlock";
import { Circle, CircleCheckBig, CircleDot } from 'lucide-react';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Progress = ({ content = "" }) => {
  // Check if content is a string or an object
  const isProgressObject = typeof content === 'object' && content !== null;

  const renderProgressObject = (progressData) => {
    return (
      <Box sx={{ width: '100%' }}>
        {progressData.plan && (
          <>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2, 
                borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                pb: 1 
              }}
            >
              Plan
            </Typography>
            <List sx={{ mb: 3 }}>
              {progressData.plan.map((step, index) => (
                <ListItem 
                  key={index}
                  sx={{
                    py: 0.5,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    {step.step_status === 'completed' ? (
                      <CircleCheckBig 
                        size={20}
                        color="#4caf50"  // success color
                      />
                    ) : step.step_status === 'in_progress' ? (
                      <Circle 
                        size={20}
                        color="rgba(255, 255, 255, 0.7)"
                      />
                    ) : (
                      <Circle 
                        size={20}
                        color="rgba(255, 255, 255, 0.7)"
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText 
                    primary={step.step_description}
                    sx={{
                      '& .MuiListItemText-primary': {
                        fontSize: '0.875rem',
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
        
        {progressData.focus && (
          <>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                pb: 1 
              }}
            >
              Current Focus
            </Typography>
            <Typography 
              variant="body2" 
            >
              {progressData.focus}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ 
        padding: "16px",
        borderRadius: "4px",
        borderLeft: "1px solid rgba(255, 255, 255, 0.12)",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        overflow: "hidden",
      }}>
        {content ? (
          isProgressObject ? (
            renderProgressObject(content)
          ) : (
            <Typography 
              variant="body2"
              component="div"
              sx={{ 
                flexGrow: 1,
                width: "100%",
                maxWidth: "100%",
                overflowY: "auto",
                '& pre': {
                  background: 'rgba(0, 0, 0, 0.2)',
                  padding: '16px',
                  borderRadius: '4px',
                  overflowX: 'auto',
                  width: "100%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                },
                '& code': {
                  background: 'rgba(0, 0, 0, 0.2)',
                  padding: '2px 4px',
                  borderRadius: '4px'
                }
              }}
            >
              <ReactMarkdown components={{ 
                code: CodeBlock,
                p: ({node, ...props}) => <Typography {...props} sx={{ mb: 2, width: "100%", maxWidth: "100%" }} />,
                h1: ({node, ...props}) => <Typography variant="h4" {...props} sx={{ mb: 2, mt: 2, width: "100%", maxWidth: "100%" }} />,
                h2: ({node, ...props}) => <Typography variant="h5" {...props} sx={{ mb: 2, mt: 2, width: "100%", maxWidth: "100%" }} />,
                h3: ({node, ...props}) => <Typography variant="h6" {...props} sx={{ mb: 2, mt: 2, width: "100%", maxWidth: "100%" }} />
              }}>
                {content}
              </ReactMarkdown>
            </Typography>
          )
        ) : (
          <Typography 
            variant="body2" 
            sx={{ 
              flexGrow: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "text.secondary"
            }}
          >
            No content to display
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Progress;
