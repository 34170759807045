import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";
import io from "socket.io-client";
import { supabase } from "../../../supabaseClient";
import { API_URL } from "../../../globals";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "transparent",
    paddingRight: 10,
    fontFamily: "monospace",
    color: "#d4d4d4",
  },
  terminalContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    borderColor: "#2f2f2f",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    overflow: "hidden",
    minHeight: 0,
    position: "relative",
    marginBottom: 20,
  },
  tabContainer: {
    display: "flex",
    gap: "1px",
    marginBottom: "8px",
    backgroundColor: "#1E1E1E",
    padding: "2px",
    borderRadius: "4px",
    width: "fit-content",
  },
  tab: {
    padding: "4px 12px",
    fontSize: "12px",
    cursor: "pointer",
    borderRadius: "2px",
    transition: "background-color 0.2s",
    userSelect: "none",
  },
  activeTab: {
    backgroundColor: "#2D2D2D",
    color: "#ffffff",
  },
  inactiveTab: {
    color: "#808080",
    "&:hover": {
      backgroundColor: "#252525",
    },
  },
  fumeTerminal: {
    fontFamily: "monospace",
    fontSize: "12px",
    whiteSpace: "pre-wrap",
    flexGrow: 1,
    overflowY: "auto",
    backgroundColor: "#1E1E1E",
    padding: 16,
    color: "#ffffff",
    borderRadius: 4,
    marginBottom: 16,
  },
  xtermjsTerminal: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
  },
};

const TerminalWindow = ({ studioId, refreshStudio, fumeTerminalContent }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const terminalRef = useRef(null);
  const xtermRef = useRef(null);
  const fitAddon = useRef(new FitAddon());
  const socketRef = useRef(null);
  const resizeObserver = useRef(null);

  const initializeTerminal = useCallback(() => {
    if (!xtermRef.current) {
      xtermRef.current = new Terminal({
        cursorBlink: true,
        theme: {
          background: "#0C0C0C",
          foreground: "#ffffff",
        },
        scrollback: 1000,
        wraparoundMode: true,
        cols: 80,
        rows: 24
      });
      xtermRef.current.loadAddon(fitAddon.current);
    }
  }, []);

  const connectSocket = useCallback(async () => {
    if (isConnected || socketRef.current) {
      return;
    }

    setIsConnected(true);

    try {
      const token = (await supabase.auth.getSession()).data.session.access_token;

      socketRef.current = io(`${API_URL}/ssh`, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });

      socketRef.current.on('connect', () => {
        xtermRef.current.write("Connected to SSH server...\r\n");
        socketRef.current.emit('ssh_connect', { studio_id: studioId });
      });

      socketRef.current.on('output', (data) => {
        xtermRef.current.write(data);
      });

      socketRef.current.on('disconnect', () => {
        xtermRef.current.write("\r\nDisconnected from SSH server...\r\n");
        setIsConnected(false);
      });

      xtermRef.current.onData(data => {
        if (socketRef.current) {
          socketRef.current.emit('input', data);
        }
      });
    } catch (error) {
      console.error("Error connecting to SSH server:", error);
      setIsConnected(false);
    }
  }, [isConnected, studioId]);

  const disconnectSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      setIsConnected(false);
    }
  }, []);

  useEffect(() => {
    initializeTerminal();
    
    return () => {
      disconnectSocket();
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [initializeTerminal, disconnectSocket]);

  useEffect(() => {
    if (activeTab === 1) {
      if (!isConnected) {
        connectSocket();
      }
      
      if (terminalRef.current && xtermRef.current) {
        const terminalElement = document.createElement('div');
        terminalElement.style.height = '100%';
        terminalElement.style.width = '100%';
        terminalRef.current.innerHTML = ''; // Clear previous content
        terminalRef.current.appendChild(terminalElement);
        
        xtermRef.current.open(terminalElement);
        fitAddon.current.fit();

        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }

        resizeObserver.current = new ResizeObserver(() => {
          fitAddon.current.fit();
        });

        resizeObserver.current.observe(terminalRef.current);
      }
    }

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [activeTab, isConnected, connectSocket]);

  useEffect(() => {
    if (fumeTerminalContent) {
      setActiveTab(0);
    }
  }, [fumeTerminalContent]);

  const Tab = ({ isActive, onClick, children }) => (
    <div
      onClick={onClick}
      style={{
        ...styles.tab,
        ...(isActive ? styles.activeTab : styles.inactiveTab),
      }}
    >
      <Typography variant="body2" component="span" sx={{ fontSize: 'inherit' }}>
        {children}
      </Typography>
    </div>
  );

  return (
    <div style={styles.root}>
      <div style={styles.tabContainer}>
        <Tab 
          isActive={activeTab === 0} 
          onClick={() => setActiveTab(0)}
        >
          Fume's Terminal
        </Tab>
        <Tab 
          isActive={activeTab === 1} 
          onClick={() => setActiveTab(1)}
        >
          Your Terminal
        </Tab>
      </div>

      {activeTab === 0 ? (
        <div
          style={styles.fumeTerminal}
          dangerouslySetInnerHTML={{ __html: fumeTerminalContent || "" }}
        />
      ) : (
        <div style={styles.terminalContainer}>
          <div style={styles.xtermjsTerminal} ref={terminalRef}></div>
        </div>
      )}
    </div>
  );
};

export default TerminalWindow;