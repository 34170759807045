import React, { useState, useRef } from 'react';
import { VncScreen } from "react-vnc";
import { Box, Typography, Button } from "@mui/material";

const styles = {
  tabContainer: {
    display: "flex",
    gap: "1px",
    marginBottom: "8px",
    backgroundColor: "#1E1E1E",
    padding: "2px",
    borderRadius: "4px",
    width: "fit-content",
  },
  tab: {
    padding: "4px 12px",
    fontSize: "12px",
    cursor: "pointer",
    borderRadius: "2px",
    transition: "background-color 0.2s",
    userSelect: "none",
  },
  activeTab: {
    backgroundColor: "#2D2D2D",
    color: "#ffffff",
  },
  inactiveTab: {
    color: "#808080",
    "&:hover": {
      backgroundColor: "#252525",
    },
  },
};

const browserFrameStyles = {
  browserFrame: {
    backgroundColor: '#2D2D2D',
    borderRadius: '10px',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  browserHeader: {
    backgroundColor: '#1E1E1E',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  browserControls: {
    display: 'flex',
    gap: '6px',
  },
  browserCircle: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  },
  addressBar: {
    backgroundColor: '#3D3D3D',
    borderRadius: '4px',
    padding: '4px 12px',
    marginLeft: '16px',
    flex: 1,
    color: '#808080',
    fontSize: '13px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  browserContent: {
    flex: 1,
    backgroundColor: '#121212',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
};

const Browser = ({ studioData, vncURL, screenshotUrl, currentUrl }) => {
  const [activeTab, setActiveTab] = useState(0); // 0 for Fume's View, 1 for Interactive
  const [isVncConnected, setIsVncConnected] = useState(false);
  const localRef = useRef();

  const Tab = ({ isActive, onClick, children }) => (
    <div
      onClick={onClick}
      style={{
        ...styles.tab,
        ...(isActive ? styles.activeTab : styles.inactiveTab),
      }}
    >
      <Typography variant="body2" component="span" sx={{ fontSize: 'inherit' }}>
        {children}
      </Typography>
    </div>
  );

  return (
    <Box sx={{ height: "100%", padding: "16px" }}>
      <div style={styles.tabContainer}>
        <Tab 
          isActive={activeTab === 0} 
          onClick={() => setActiveTab(0)}
        >
          Fume's View
        </Tab>
        <Tab 
          isActive={activeTab === 1} 
          onClick={() => setActiveTab(1)}
        >
          Interactive
        </Tab>
      </div>

      <Box
        sx={{
          position: "relative",
          height: "calc(100% - 40px)",
          backgroundColor: '#121212',
        }}
      >
        {activeTab === 0 ? (
          <div style={browserFrameStyles.browserFrame}>
            <div style={browserFrameStyles.browserHeader}>
              <div style={browserFrameStyles.browserControls}>
                <div style={{ ...browserFrameStyles.browserCircle, backgroundColor: '#FF5F57' }}></div>
                <div style={{ ...browserFrameStyles.browserCircle, backgroundColor: '#FFBD2E' }}></div>
                <div style={{ ...browserFrameStyles.browserCircle, backgroundColor: '#28C840' }}></div>
              </div>
              <div style={browserFrameStyles.addressBar}>
                {currentUrl || `chrome://newtab`}
              </div>
            </div>
            <div style={browserFrameStyles.browserContent}>
              <img 
                src={screenshotUrl || `https://fume.b-cdn.net/default.png`}
                alt="Browser View"
                style={{
                  width: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  borderRadius: "0 0 6px 6px",
                  display: 'block',
                  marginTop: 0,
                }}
              />
            </div>
          </div>
        ) : (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            {!isVncConnected && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 3,
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    textAlign: "center",
                    marginBottom: "12px",
                  }}
                >
                  VNC Password: <strong>{studioData?.vnc_password?.substring(0, 8)}</strong>
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() =>
                    window.open(
                      `https://vnc.${studioData.instance_name}.fumedev.com/vnc.html`,
                      "_blank"
                    )
                  }
                  sx={{
                    textTransform: 'none',
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                    color: 'rgba(255, 255, 255, 0.85)',
                    '&:hover': {
                      borderColor: 'rgba(255, 255, 255, 0.5)',
                    }
                  }}
                >
                  Open VNC Viewer
                </Button>
              </Box>
            )}
            <VncScreen
              url={vncURL}
              rfbOptions={{
                credentials: {
                  password: studioData?.vnc_password?.substring(0, 8),
                },
              }}
              scaleViewport
              style={{
                borderRadius: "10px",
                width: "100%",
                height: "100%",
                position: "relative",
                zIndex: 2,
              }}
              ref={localRef}
              onConnect={() => setIsVncConnected(true)}
              onDisconnect={() => setIsVncConnected(false)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Browser; 