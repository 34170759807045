import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Gitlab } from 'lucide-react';

const GitlabModal = ({ open, onClose }) => {
  const [accessToken, setAccessToken] = useState('');

  const handleSubmit = async () => {
    // Handle GitHub integration logic here
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Gitlab size={24} />
          <Box>
            <Typography 
              sx={{ 
                color: '#fff',
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 0.5
              }}
            >
              GitLab Integration
            </Typography>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem'
            }}>
              Connect your GitLab repositories
            </Typography>
          </Box>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              To integrate with GitHub, you'll need to provide a personal access token
              with the following permissions:
              <Box component="ul" sx={{ mt: 2, pl: 2 }}>
                <li>repo (Full control of private repositories)</li>
                <li>read:org (Read organization data)</li>
                <li>read:user (Read user data)</li>
              </Box>
            </Typography>
          </Box>

          <TextField
            placeholder="GitHub Personal Access Token"
            fullWidth
            value={accessToken}
            onChange={(e) => setAccessToken(e.target.value)}
            type="password"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#1a1a1a',
                color: '#fff',
                '& fieldset': {
                  borderColor: '#2a2a2a',
                },
                '&:hover fieldset': {
                  borderColor: '#333',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#444',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '14px 16px',
                '&::placeholder': {
                  color: '#666',
                  opacity: 1,
                },
              },
            }}
          />
        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid #2a2a2a',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#888',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#fff'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!accessToken.trim()}
            sx={{
              border: '1px solid #333',
              backgroundColor: 'transparent',
              color: accessToken.trim() ? '#fff' : '#666',
              px: 3,
              '&:hover': {
                backgroundColor: '#252525',
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: '#666',
                border: '1px solid #2a2a2a',
              }
            }}
          >
            Connect
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GitlabModal; 