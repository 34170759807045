import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CssBaseline,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import CheckIcon from "@mui/icons-material/Check";

const tealTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: '#121212',
      paper: '#121212',
    },
    primary: {
      main: "#424242",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.12)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#424242',
            },
          },
        },
      },
    },
  },
});

const initialQuestions = [
  {
    id: "q1",
    label: "Studio Content",
    description:
      "This is a system prompt describing Fume what's in this studio. You may provide details about the structure and the technologies used in the projects you cloned. It might be helpful to include some of the most important directories and files here.",
  },
  {
    id: "q2",
    label: "Notes to Remember",
    description:
      "These are the most important pieces of information you want Fume to remember. These will not be forgotten no matter how long is the task.",
  },
  {
    id: "q3",
    label: "Fume Ignore",
    description:
      "All of the files and directories that you want Fume to ignore. Everything igonred by your .gitignore file will be automatically ignored by Fume. You do not have list them here.",
  },
  {
    id: "q4",
    label: "App Start",
    type: "unix",
    description:
      "Provide a series of Unix commands that would fully start your app. This does not need to include the initial setup commands like `npm install` or `yarn install` as they will be preserved in this studio across tasks. But this comands should start all of the services necesary to test this app. List the necessary commands - each on a new line. You can create multiple processes for multiple apps. You should always start with by running `cd /home/fume/Documents/you-project-name` to navigate to the project directory.",
  },
];

const UnixCommandField = ({ value, onChange }) => (
  <Box
    sx={{
      position: "relative",
      "&:focus-within": {
        outline: "1px solid rgba(255, 255, 255, 0.12)",
        outlineOffset: "1px",
      },
    }}
  >
    <SyntaxHighlighter
      language="bash"
      style={{
        ...dracula,
        'pre[class*="language-"]': {
          ...dracula['pre[class*="language-"]'],
          background: '#1e1e1e',
          margin: 0,
        },
      }}
      customStyle={{
        padding: "12px",
        borderRadius: "4px",
        fontSize: "0.875rem",
        lineHeight: "1.5",
        minHeight: "80px",
        backgroundColor: '#1e1e1e',
        border: '1px solid rgba(255, 255, 255, 0.12)',
      }}
    >
      {value}
    </SyntaxHighlighter>
    <textarea
      value={value}
      onChange={onChange}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "transparent",
        color: "transparent",
        caretColor: "white",
        padding: "12px",
        border: "none",
        resize: "none",
        fontSize: "0.875rem",
        lineHeight: "1.5",
        fontFamily: "monospace",
      }}
    />
  </Box>
);

const parseInitialState = (initialState) => {
  const parts = initialState.split('---\n');
  const answers = {};
  const additionalProcesses = [];

  // Parse the first three questions
  for (let i = 0; i < 3; i++) {
    answers[`q${i + 1}`] = parts[i] ? parts[i].trim() : '';
  }

  // Parse the processes
  if (parts.length > 3) {
    const processes = parts[3].split('===\n');
    answers['q4'] = processes[0].trim();

    // Add additional processes
    for (let i = 1; i < processes.length; i++) {
      const newId = `q${i + 4}`;
      answers[newId] = processes[i].trim();
      additionalProcesses.push({
        id: newId,
        label: `Process ${i}`,
        type: "unix",
        description: "Provide a series of Unix commands for this process.",
      });
    }
  }

  return { answers, additionalProcesses };
};

const FormPage = ({ studioId, initialState = '' }) => {
    const { answers: initialAnswers, additionalProcesses } = parseInitialState(initialState);
    const [questions, setQuestions] = useState([...initialQuestions, ...additionalProcesses]);
    const [answers, setAnswers] = useState(initialAnswers);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const formRef = useRef(null);
    const lastQuestionRef = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);
  
    const handleChange = (id, value) => {
      setAnswers((prev) => ({ ...prev, [id]: value }));
    };
  
    const handleSubmit = async (e) => {
    e.preventDefault();

    let formattedOutput = "";

    // Add answers for the first three questions
    for (let i = 0; i < 3; i++) {
      formattedOutput += `${answers[`q${i + 1}`] || ""}\n`;
      if (i < 2) formattedOutput += "---\n";
    }

    // Add all Unix command processes, including the initial one
    const processes = questions.filter((q) => q.type === "unix");
    if (processes.length > 0) {
      formattedOutput += "---\n";
      processes.forEach((process, index) => {
        formattedOutput += `${answers[process.id] || ""}\n`;
        if (index < processes.length - 1) formattedOutput += "===\n";
      });
    }

    setIsSaving(true);
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.post(
        `${API_URL}/studio/save-file`,
        {
          studio_id: studioId,
          file_path: "./.fumeconfig",
          content: formattedOutput,
        },
        {
          headers: headers,
        }
      );
      if (response.status !== 200) {
        console.log("Error saving file");
        return;
      }
      setSaveSuccessful(true);
      setTimeout(() => setSaveSuccessful(false), 3000);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const addProcess = () => {
    const newId = `q${questions.length + 1}`;
    const newQuestion = {
      id: newId,
      label: `Process ${questions.length - 3}`,
      type: "unix",
      description: "Provide a series of Unix commands for this process.",
    };
    setQuestions([...questions, newQuestion]);
    setShouldScroll(true);
  };

  const deleteProcess = (id) => {
    setQuestions(questions.filter((q) => q.id !== id));
    setAnswers((prevAnswers) => {
      const { [id]: deletedAnswer, ...remainingAnswers } = prevAnswers;
      return remainingAnswers;
    });
  };

  useEffect(() => {
    if (shouldScroll && lastQuestionRef.current) {
      lastQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setShouldScroll(false);
    }
  }, [questions, shouldScroll]);

  return (
    <ThemeProvider theme={tealTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {saveSuccessful && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              backgroundColor: '#1e4620',
              color: '#fff',
            }}
          >
            File saved successfully
          </Alert>
        )}
        <Container
          maxWidth="md"
          sx={{
            py: 2,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Typography 
            variant="h5" 
            component="h1" 
            gutterBottom
            sx={{
              color: '#fff',
              fontWeight: 500,
              mb: 2
            }}
          >
            Fume Config
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            ref={formRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
              {questions.map((q, index) => (
                <Box
                  key={q.id}
                  mb={2}
                  ref={index === questions.length - 1 ? lastQuestionRef : null}
                  sx={{
                    borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
                    pb: 2,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography 
                      variant="subtitle1" 
                      gutterBottom
                      sx={{
                        color: '#fff',
                        fontWeight: 500
                      }}
                    >
                      {q.label}
                    </Typography>
                    {q.type === "unix" && index > 3 && (
                      <IconButton
                        onClick={() => deleteProcess(q.id)}
                        color="error"
                        size="small"
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Typography
                    variant="body2"
                    paragraph
                    sx={{ 
                      mb: 1,
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '0.875rem',
                    }}
                  >
                    {q.description}
                  </Typography>
                  {q.type === "unix" ? (
                    <UnixCommandField
                      value={answers[q.id] || ""}
                      onChange={(e) => handleChange(q.id, e.target.value)}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={answers[q.id] || ""}
                      onChange={(e) => handleChange(q.id, e.target.value)}
                      variant="outlined"
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        borderRadius: 1,
                      }}
                    />
                  )}
                </Box>
              ))}
            </Box>
            <Box 
              sx={{ 
                mb: 2,
                display: 'flex',
                gap: 2,
                borderTop: '1px solid rgba(255, 255, 255, 0.12)',
                pt: 2,
              }}
            >
              <Button
                onClick={addProcess}
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  borderColor: '#555',
                  color: '#fff',
                  '&:hover': {
                    borderColor: '#777',
                    backgroundColor: '#1e1e1e',
                  },
                }}
              >
                Add Process
              </Button>
              <Button 
                type="submit" 
                variant="contained"
                disabled={isSaving}
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  backgroundColor: '#424242',
                  '&:hover': {
                    backgroundColor: '#505050',
                  },
                }}
              >
                {isSaving ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default FormPage;