import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import axios from 'axios';
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";

const IntegrationsPage = () => {
  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);
  const [linearMappings, setLinearMappings] = useState([]);
  const [studioOptions, setStudioOptions] = useState([]);
  const [selectedLinearDataSource, setSelectedLinearDataSource] = useState(-1);
  const [linearURL, setLinearURL] = useState("");

  // Function to check slack integration status
  const checkSlackIntegration = async () => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    try {
      const response = await axios.get(`${API_URL}/check-slack-integration`, { headers });
      setIsSlackIntegrated(response.data.slack_integration);
    } catch (error) {
      console.error("Failed to check Slack integration:", error);
    }
  };

  // Function to retrieve available studios
  const getAvailableStudios = async () => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    try {
      const response = await axios.get(`${API_URL}/studio/seeds`, { headers });
      if (response.data) {
        const studios = response.data.filter((studio) => studio.status === "READY");
        const options = studios.map((studio, index) => ({
          label: studio.display_name,
          value: studio.id,
        }));
        setStudioOptions(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to get linear mappings
  const getLinearMappings = async () => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    try {
      const response = await axios.get(`${API_URL}/get-linear-mappings`, { headers });
      setLinearMappings(response.data);
    } catch (error) {
      console.error("Failed to retrieve Linear mappings:", error);
    }
  };

  const handleLinearConnectSubmit = async () => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    try {
      await axios.post(`${API_URL}/add-linear-mapping`, {
        linear_url: linearURL,
        studio_id: selectedLinearDataSource,
      }, { headers });
      window.location.href = process.env.REACT_APP_LINEAR_OAUTH_URL;
    } catch (error) {
      console.error("Failed to add Linear mapping:", error);
    }
  };

  useEffect(() => {
    checkSlackIntegration();
    getLinearMappings();
    getAvailableStudios();
  }, []);

  const linaerIntegrationCheck = linearMappings.some(mapping => mapping.oauth_token);

  return (
    <Box>
      <Typography variant="h4">Integrations</Typography>
      <Box sx={{ p: 2, mt: 1, mb: 1, border: 1, borderColor: "divider" }}>
        <Typography variant="h6">Slack</Typography>
        {isSlackIntegrated ? (
          <Box sx={{ display: "flex", alignItems: "center", color: green[500] }}>
            <CheckIcon sx={{ marginRight: 1 }} />
            <Typography>Slack Integration Active</Typography>
          </Box>
        ) : (
          <Typography>
            You don't have your Slack integrations set up yet. Please see doc.fumedev.com to learn how.
          </Typography>
        )}
      </Box>
      <Box sx={{ p: 2, mt: 1, mb: 1, border: 1, borderColor: "divider" }}>
        <Typography variant="h6">Linear</Typography>
        {linaerIntegrationCheck ? (
          <Box sx={{ display: "flex", alignItems: "center", color: green[500] }}>
            <CheckIcon sx={{ marginRight: 1 }} />
            <Typography>Linear Integration Active</Typography>
          </Box>
        ) : (
          <>
            <Typography variant="body">
              Setup the Linear integration for Fume to be able pick up tasks from your workspace.
            </Typography>
            <TextField
              label="Linear Workspace URL"
              variant="outlined"
              placeholder="linear.app/team-name"
              value={linearURL}
              onChange={(e) => setLinearURL(e.target.value)}
              sx={{ mr: 2, width: "100%", marginTop: 3 }}
            />
            <FormControl variant="outlined" sx={{ minWidth: 120, marginTop: 3 }}>
              <InputLabel>Select a studio</InputLabel>
              <Select
                value={selectedLinearDataSource}
                onChange={(event) => setSelectedLinearDataSource(event.target.value)}
                label="Select a Studio"
                sx={{ width: "300px" }}
              >
                {studioOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ ml: 2, mt: 4 }}
              onClick={handleLinearConnectSubmit}
            >
              Connect
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default IntegrationsPage;
