import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";

const TeamPage = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamData, setTeamData] = useState({});
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserFullName, setNewUserFullName] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [isAddingAUser, setIsAddingAUser] = useState(false);

  const fetchTeams = async () => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    try {
      const response = await axios.get(`${API_URL}/get-teams`, { headers });
      setTeamMembers(response.data.teams[0].members);
      setTeamData(response.data.teams[0]);
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    }
  };

  const handleRemoveUser = async (user) => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    try {
      const response = await axios.post(
        `${API_URL}/remove-member-from-team`,
        { member_id: user.id, team_id: teamData.id },
        { headers }
      );
      await fetchTeams(); // Refresh the list of users
    } catch (error) {
      console.error(`Error removing user: ${user.email}`, error);
    }
  };

  const handleNewUserSubmit = async () => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    const userData = {
      email: newUserEmail,
      full_name: newUserFullName,
      password: newUserPassword,
      team_id: teamData.id,
    };
    try {
      const response = await axios.post(`${API_URL}/add-member-to-team`, userData, { headers });
      await fetchTeams(); // Refresh the list of users
      setIsAddingAUser(false);
    } catch (error) {
      console.error(`Error adding user: ${newUserEmail}`, error);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const team_data_columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "full_name", headerName: "Full Name", width: 200 },
    { field: "role", headerName: "Role", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleRemoveUser(params.row)}>
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h4">Team</Typography>
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Manage Users
      </Typography>
      <div style={{ height: 400, width: "100%", paddingTop: 20, paddingBottom: 10 }}>
        <DataGrid rows={teamMembers} columns={team_data_columns} pageSize={5} rowsPerPageOptions={[5]} getRowId={(row) => row.email} />
      </div>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => setIsAddingAUser(true)}>
          Add Team Member
        </Button>
      </Box>
      {isAddingAUser && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <TextField label="Full Name" variant="outlined" value={newUserFullName} onChange={(e) => setNewUserFullName(e.target.value)} sx={{ mr: 2, width: "100%" }} />
          <TextField label="Email" variant="outlined" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} sx={{ mr: 2, width: "100%" }} />
          <TextField label="Password" variant="outlined" type="password" value={newUserPassword} onChange={(e) => setNewUserPassword(e.target.value)} sx={{ mr: 2, width: "100%" }} />
          <Button type="submit" variant="contained" color="primary" onClick={handleNewUserSubmit} sx={{ py: 2 }}>
            Add
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TeamPage;
