import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Eye } from 'lucide-react';

const BrowserLookCard = ({ animate = true }) => {
  const [isRevealing, setIsRevealing] = useState(animate);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
    }, 1000);

    return () => clearTimeout(revealTimer);
  }, [animate]);

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: '60px',
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Eye 
            size={20}
            style={{ 
              marginRight: '12px',
              color: '#4f8cc9',
            }}
          />
          <Typography
            component="span"
            sx={{
              fontSize: '0.875rem',
            }}
          >
            Looked at the browser
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BrowserLookCard; 