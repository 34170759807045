import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Terminal, ChevronUp } from 'lucide-react';

const UnixCommandCard = ({ 
  command, 
  output,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showCommand, setShowCommand] = useState(!animate);
  const [showOutput, setShowOutput] = useState(!animate);
  const [typedCommand, setTypedCommand] = useState(animate ? '' : command);
  const [cursorVisible, setCursorVisible] = useState(animate);
  const [isCollapsed, setIsCollapsed] = useState(externalCollapsed);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowCommand(true);
      setShowOutput(true);
      setCursorVisible(false);
      return;
    }

    // Initial reveal animation
    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowCommand(true), 300);
    }, 1000);

    // Cursor blink effect
    const cursorInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);

    return () => {
      clearTimeout(revealTimer);
      clearInterval(cursorInterval);
    };
  }, [animate]);

  // Type command effect
  useEffect(() => {
    if (!animate) return;

    if (showCommand && typedCommand.length < command.length) {
      const typeTimer = setTimeout(() => {
        setTypedCommand(command.slice(0, typedCommand.length + 1));
      }, 50);
      return () => clearTimeout(typeTimer);
    } else if (showCommand && typedCommand.length === command.length) {
      const outputTimer = setTimeout(() => {
        setShowOutput(true);
      }, 500);
      return () => clearTimeout(outputTimer);
    }
  }, [showCommand, typedCommand, command, animate]);

  useEffect(() => {
    setIsCollapsed(externalCollapsed);
  }, [externalCollapsed]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          overflowY: 'hidden',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          backdropFilter: isRevealing ? 'blur(4px)' : 'none',
        }}
      >
        {isRevealing && animate && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(15, 15, 15, 0.5)',
              backdropFilter: 'blur(4px)',
            }}
          />
        )}
        
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: isRevealing ? 'absolute' : 'relative',
            top: isRevealing ? '50%' : 0,
            left: isRevealing ? '50%' : 0,
            transform: isRevealing ? 'translate(-50%, -50%)' : 'none',
            transition: animate ? 'all 0.3s ease-out' : 'none',
            fontSize: isRevealing ? '1rem' : '0.75rem',
            mb: isRevealing ? 0 : 1,
            opacity: showCommand ? 1 : 0.8,
            zIndex: 1,
            width: isRevealing ? 'auto' : '100%',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Terminal 
              size={20}
              style={{ 
                marginRight: '8px',
                transition: animate ? 'all 0.3s ease-out' : 'none',
                color: '#337a3d'
              }} 
            />
            <Typography component="span" fontSize="0.9rem">
              Terminal
            </Typography>
          </Typography>
          
          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {showCommand && !isCollapsed && (
          <Box 
            sx={{ 
              opacity: showCommand ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              position: 'relative',
              zIndex: 1,
              maxHeight: 420,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#0f0f0f',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#2a2a2a',
                borderRadius: '4px',
              },
              scrollbarWidth: 'thin',
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace',
                fontSize: '0.70rem',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                component="span"
                sx={{ color: '#337a3d', mr: 1 }}
              >
                $
              </Typography>
              <Typography component="span" fontSize="0.85rem">
                {typedCommand}
                {cursorVisible && showCommand && typedCommand.length < command.length && (
                  <span style={{ opacity: cursorVisible ? 1 : 0 }}>▋</span>
                )}
              </Typography>
            </Box>

            {showOutput && (
              <Box
                sx={{
                  mt: 0,
                  p: 2,
                  borderRadius: 1,
                  fontFamily: 'monospace',
                  fontSize: '0.80rem',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                  animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
                }}
              >
                {output.split('\n').map((line, index) => (
                  <div
                    key={index}
                    style={{
                      animation: animate ? `fadeIn 0.2s ease-in forwards` : 'none',
                      animationDelay: animate ? `${index * 0.05}s` : '0s',
                      opacity: animate ? 0 : 1,
                    }}
                  >
                    {line}
                  </div>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UnixCommandCard; 