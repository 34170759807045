import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Github } from 'lucide-react';

const GithubModal = ({ open, onClose }) => {
  const [accessToken, setAccessToken] = useState('');

  const handleSubmit = async () => {
    // Handle GitHub integration logic here
    window.open('https://github.com/apps/fumedev/installations/select_target', '_blank');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Github size={24} style={{ color: '#fff' }} />
          <Box>
            <Typography 
              sx={{ 
                color: '#fff',
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 0.5
              }}
            >
              GitHub Integration
            </Typography>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem'
            }}>
              Connect your GitHub organization
            </Typography>
          </Box>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              You can connect Fume to your Github organization recieves pull requests. To integrate Fume with GitHub, you'll need to install Fume Github App on your organization:
              with the following permissions:
              <Box component="ul" sx={{ mt: 2, pl: 2 }}>
                <li>Click on the "Install App" button below</li>
                <li>Select the organization you'd like to connect</li>
                <li>Click "Authorize"</li>
              </Box>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid #2a2a2a',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#888',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#fff'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{
              border: '1px solid #333',
              backgroundColor: 'transparent',
              color: '#fff',
              px: 3,
              '&:hover': {
                backgroundColor: '#252525',
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: '#666',
                border: '1px solid #2a2a2a',
              }
            }}
          >
            Install App
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GithubModal; 