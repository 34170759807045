import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Terminal } from 'lucide-react';
import axios from "axios";
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";

const AddStudioModal = ({ open, onClose, onSuccess }) => {
  const [name, setName] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      let body = { display_name: name };
      let res = await axios.post(`${API_URL}/studio/create`, body, { headers });
      
      if (res.status !== 200) {
        alert("You have reached the maximum number of studios for your account. Contact contact@fumedev.com to upgrade your account.");
        return;
      }

      const newStudio = {
        id: res.data.id,
        display_name: name,
        status: 'BOOTING',
        created_at: new Date().toISOString(),
        tags: []
      };

      onSuccess(newStudio);
      onClose();
      setName("");
    } catch (error) {
      console.error("Error creating studio:", error);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box>
          <Typography 
            sx={{ 
              color: '#fff',
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 0.5
            }}
          >
            Create New Studio
          </Typography>
          <Typography sx={{ 
            color: '#888',
            fontSize: '0.875rem'
          }}>
            Set up a new development environment
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
            display: 'flex',
            gap: 3,
            alignItems: 'center'
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              Studios are persistent development environments that Fume can re-use
              across multiple tasks. You can setup multiple codebases or sign in to
              online services, just like your local development environment.
            </Typography>
          </Box>

          <TextField
            autoFocus
            placeholder="My Development Studio"
            fullWidth
            value={name}
            onChange={handleNameChange}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#1a1a1a',
                color: '#fff',
                '& fieldset': {
                  borderColor: '#2a2a2a',
                },
                '&:hover fieldset': {
                  borderColor: '#333',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#444',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '14px 16px',
                '&::placeholder': {
                  color: '#666',
                  opacity: 1,
                },
              },
            }}
          />
        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid #2a2a2a',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#888',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#fff'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!name.trim()}
            sx={{
              border: '1px solid #333',
              backgroundColor: 'transparent',
              color: name.trim() ? '#fff' : '#666',
              px: 3,
              '&:hover': {
                backgroundColor: '#252525',
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: '#666',
                border: '1px solid #2a2a2a',
              }
            }}
          >
            Create Studio
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddStudioModal;
