import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { GitPullRequest, ChevronUp, ExternalLink } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../CodeBlock';

const CreatePRCard = ({ 
  title,
  body,
  prLink,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showContent, setShowContent] = useState(!animate);
  const [typedTitle, setTypedTitle] = useState(animate ? '' : title);
  const [cursorVisible, setCursorVisible] = useState(animate);
  const [isCollapsed, setIsCollapsed] = useState(externalCollapsed ?? defaultCollapsed);
  const [isLinkHovered, setIsLinkHovered] = useState(false);

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowContent(true);
      setCursorVisible(false);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowContent(true), 300);
    }, 1000);

    const cursorInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);

    return () => {
      clearTimeout(revealTimer);
      clearInterval(cursorInterval);
    };
  }, [animate]);

  // Type title effect
  useEffect(() => {
    if (!animate) return;

    if (showContent && typedTitle.length < title.length) {
      const typeTimer = setTimeout(() => {
        setTypedTitle(title.slice(0, typedTitle.length + 1));
      }, 50);
      return () => clearTimeout(typeTimer);
    }
  }, [showContent, typedTitle, title, animate]);

  useEffect(() => {
    if (externalCollapsed !== undefined) {
      setIsCollapsed(externalCollapsed);
    }
  }, [externalCollapsed]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <GitPullRequest 
              size={20}
              style={{ 
                marginRight: '12px',
                color: '#2ea043',
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Typography
                component="span"
                sx={{
                  fontSize: '0.875rem',
                  mr: 1,
                  color: '#666',
                }}
              >
                Created PR:
              </Typography>
              <Typography
                component="span"
                sx={{
                  color: '#2ea043',
                  fontSize: '0.875rem',
                  fontFamily: 'monospace',
                  backgroundColor: 'rgba(46, 160, 67, 0.1)',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {typedTitle}
                {cursorVisible && showContent && typedTitle.length < title.length && (
                  <span style={{ opacity: cursorVisible ? 1 : 0 }}>▋</span>
                )}
              </Typography>
            </Box>
          </Box>

          {!isRevealing && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Box
                component="a"
                href={prLink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#2ea043',
                  textDecoration: 'none',
                  mr: 1,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    color: '#3fb556',
                  },
                }}
                onMouseEnter={() => setIsLinkHovered(true)}
                onMouseLeave={() => setIsLinkHovered(false)}
              >
                <ExternalLink 
                  size={18}
                  style={{
                    transform: isLinkHovered ? 'translateY(-2px)' : 'none',
                    transition: 'transform 0.2s ease',
                  }}
                />
              </Box>
              <IconButton
                onClick={handleToggleCollapse}
                size="small"
                sx={{
                  color: '#fff',
                  transition: 'transform 0.2s ease',
                  transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              >
                <ChevronUp size={20} />
              </IconButton>
            </Box>
          )}
        </Box>

        {showContent && !isCollapsed && (
          <Box
            sx={{
              mt: 2,
              opacity: showContent ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              maxHeight: '400px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(255, 255, 255, 0.1) transparent',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                p: 2,
                borderRadius: 1,
                '& code': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  padding: '2px 6px',
                  borderRadius: '4px',
                  fontSize: '0.85em',
                  fontFamily: 'monospace',
                },
                '& pre': {
                  backgroundColor: '#1a1a1a',
                  padding: '12px',
                  borderRadius: '4px',
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    height: '8px',
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                  },
                },
                '& a': {
                  color: '#58a6ff',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                },
                '& ul, & ol': {
                  paddingLeft: '20px',
                  marginTop: '8px',
                  marginBottom: '8px',
                },
                '& li': {
                  marginBottom: '4px',
                },
                '& p': {
                  marginTop: '8px',
                  marginBottom: '8px',
                  '&:first-of-type': {
                    marginTop: 0,
                  },
                  '&:last-child': {
                    marginBottom: 0,
                  },
                },
              }}
            >
              <ReactMarkdown
                components={{
                  code: ({ node, inline, className, children, ...props }) => {
                    if (inline) {
                      return <code className={className} {...props}>{children}</code>;
                    }
                    return <CodeBlock className={className}>{children}</CodeBlock>;
                  },
                }}
              >
                {body}
              </ReactMarkdown>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CreatePRCard; 