import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import FloatingTabSwitch from './FloatingTabSwitch/FloatingTabSwitch';

const ChatHeader = ({
  activeTab,
  setActiveTab,
  tabWithNotification,
  title,
  isEditingTitle,
  setIsEditingTitle,
  editedTitle,
  setEditedTitle,
  onTitleSubmit,
  showTabs
}) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        height: '64px',
        backgroundColor: 'rgba(18, 18, 18, 0.8)',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 3,
        zIndex: 1000,
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        marginLeft: { xs: 0, lg: '240px' }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, maxWidth: '60%' }}>
        {isEditingTitle ? (
          <TextField
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            onKeyDown={onTitleSubmit}
            onBlur={() => setIsEditingTitle(false)}
            autoFocus
            size="small"
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                color: 'white',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }}
          />
        ) : (
          <Typography
            variant="h6"
            onClick={() => {
              setIsEditingTitle(true);
              setEditedTitle(title);
            }}
            sx={{
              cursor: 'pointer',
              color: 'white',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
      
      {showTabs && (
        <FloatingTabSwitch
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabWithNotification={tabWithNotification}
        />
      )}
    </Box>
  );
};

export default ChatHeader; 