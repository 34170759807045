export const API_URL = process.env.REACT_APP_API_URL || '__REACT_APP_API_URL__';
export const ON_PREM = process.env.REACT_APP_ON_PREM === "true" || '__REACT_APP_ON_PREM__' === "true";



export const models = [
  { display_name: "GPT-4 Turbo", name: "gpt-4-turbo" },
  { display_name: "Claude Haiku", name: "claude-3-haiku-20240307" },
  { display_name: "Claude Sonnet", name: "claude-3-sonnet-20240229" },
  { display_name: "Claude Opus", name: "claude-3-opus-20240229" },
];
