import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Chip,
  Snackbar,
  Alert,
  Tooltip,
  CircularProgress,
  InputBase,
} from "@mui/material";
import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import { BookOpenText } from 'lucide-react';
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";

const PlaybooksPage = () => {
  const [playbooks, setPlaybooks] = useState([]);
  const [filteredPlaybooks, setFilteredPlaybooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPlaybooks() {
    setIsRefreshing(true);
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/studio/ui-recordings`, { headers });
      
      const transformedPlaybooks = response.data.recordings.map(recording => ({
        id: recording._id,
        name: recording.name || `Untitled Playbook (${recording._id.slice(-6)})`,
        type: 'Web Flow',
        created_at: recording.created_at
      }));
      
      setPlaybooks(transformedPlaybooks);
      setFilteredPlaybooks(transformedPlaybooks);
    } catch (error) {
      console.log(error);
      setPlaybooks([]);
      setFilteredPlaybooks([]);
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  }

  useEffect(() => {
    getUser();
    getPlaybooks();
  }, []);

  useEffect(() => {
    const filtered = playbooks.filter(playbook => 
      playbook.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredPlaybooks(filtered);
  }, [searchQuery, playbooks]);

  const getTypeColor = (type) => {
    switch (type) {
      case 'Web Flow':
        return 'rgba(46, 78, 165, 0.6)';
      case 'Markdown':
        return 'rgba(115, 92, 145, 0.6)';
      default:
        return 'rgba(56, 56, 56, 0.6)';
    }
  };

  return (
    <>
      <NavBar user={user} />
      <Box sx={{ 
        minHeight: '100vh',
        pt: 8,
        px: 4,
        backgroundColor: '#121212',
      }}>
        <Box sx={{
          maxWidth: '1200px',
          margin: '0 auto',
        }}>
          {/* Header Section */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 6
          }}>
            <Box>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600, 
                  color: '#fff',
                  mb: 1
                }}
              >
                Playbooks
              </Typography>
              <Typography sx={{ color: '#888', fontSize: '0.875rem' }}>
                Teach tribal knowledge to Fume
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#1a1a1a',
                  borderRadius: 1,
                  border: '1px solid #2a2a2a',
                  px: 2,
                  py: 1,
                  '&:focus-within': {
                    borderColor: '#333',
                  },
                }}
              >
                <SearchIcon sx={{ color: '#666', mr: 1 }} />
                <InputBase
                  placeholder="Search playbooks..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    color: '#fff',
                    fontSize: '0.875rem',
                    width: '200px',
                    '& input::placeholder': {
                      color: '#666',
                      opacity: 1,
                    },
                  }}
                />
              </Box>
              <Tooltip title="Create New Playbook" placement="left">
                <IconButton
                  onClick={() => {}} // TODO: Implement playbook creation
                  sx={{
                    border: '1px solid #333',
                    borderRadius: 1,
                    p: 1,
                    '&:hover': {
                      backgroundColor: '#252525',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#fff'
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* Playbooks Grid */}
          {isLoading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px'
            }}>
              <CircularProgress size={32} sx={{ color: '#fff' }} />
            </Box>
          ) : (
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              gap: 3,
              pb: 6,
              opacity: isRefreshing ? 0.5 : 1,
              pointerEvents: isRefreshing ? 'none' : 'auto',
              position: 'relative'
            }}>
              {isRefreshing && (
                <CircularProgress 
                  size={32} 
                  sx={{ 
                    color: '#fff',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1
                  }} 
                />
              )}
              {filteredPlaybooks.map((playbook) => (
                <Box
                  key={playbook.id}
                  onClick={() => navigate(`/playbook/${playbook.id}`)}
                  sx={{
                    backgroundColor: '#1a1a1a',
                    borderRadius: 1,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    border: '1px solid #2a2a2a',
                    '&:hover': {
                      backgroundColor: '#171717',
                      borderColor: '#333',
                    },
                  }}
                >
                  <Box sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                      <Typography sx={{ 
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: '#fff'
                      }}>
                        {playbook.name}
                      </Typography>
                      <Chip
                        label={playbook.type}
                        size="small"
                        sx={{
                          backgroundColor: getTypeColor(playbook.type),
                          color: '#fff',
                          fontWeight: 500,
                          fontSize: '0.675rem',
                          height: '24px',
                          border: 'none',
                          textTransform: 'capitalize'
                        }}
                      />
                    </Box>
                    
                    <Typography sx={{ 
                      color: '#888',
                      fontSize: '0.875rem',
                      lineHeight: 1.5
                    }}>
                      {playbook.description}
                    </Typography>
                  </Box>
                </Box>
              ))}

              {filteredPlaybooks.length === 0 && (
                <Box sx={{
                  gridColumn: '1 / -1',
                  p: 6,
                  textAlign: 'center',
                  border: '1px dashed #333',
                  borderRadius: 1,
                  backgroundColor: '#1a1a1a',
                }}>
                  <BookOpenText size={32} style={{ color: '#444', marginBottom: '16px' }} />
                  <Typography sx={{ 
                    color: '#888', 
                    fontSize: '0.875rem',
                    maxWidth: '400px',
                    margin: '0 auto'
                  }}>
                    {playbooks.length === 0 
                      ? "No playbooks available. Create your first playbook to start automating your development workflow."
                      : "No playbooks found matching your search."}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="success"
            sx={{
              backgroundColor: '#252525',
              color: '#fff',
              '& .MuiAlert-icon': {
                color: '#fff'
              }
            }}
          >
            Playbook created successfully
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default PlaybooksPage; 